import React, { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Steps, Form } from 'antd';
import TestSuiteConfigSection from 'components/TestSuites/TestSuiteConfigSection';
import TestSuiteInfoSection from 'components/TestSuites/TestSuiteInfoSection';
import { useSelector, useDispatch } from 'react-redux';
import { currentTestSuiteSelector } from 'redux/TestSuites/selectors';
import TestSuitePlatformEditionSection from 'components/TestSuites/TestSuitePlatformEditionSection';
import { getTestSuiteDetail } from 'redux/TestSuites/slice';
import SharedDrawer from 'components/SharedDrawer';
import { createJob } from 'redux/Jobs/slice';
import ScheduleSection from 'components/Jobs/ScheduleSection';
import TestCasesSelectionSection from 'components/Jobs/TestCasesSelectionSection';
import { getTestCases } from 'redux/TestCases/slice';
import {
  testCasesByTestSuiteIdSelector,
  testCasesLoadingByTestSuiteIdSelector,
} from 'redux/TestCases/selectors';
import NotificationSection from 'components/Jobs/NotificationSection';
import { jobLoadingSelector } from 'redux/Jobs/selector';
import { getAppleTeams } from 'redux/AppleTeams/slice';
import { appleTeamsSelector } from 'redux/AppleTeams/selectors';
import { connectedClientIdsFilterSelector } from 'redux/ClientDevices/selectors';

const { Step } = Steps;

export default function JobCreationDrawer({ visible, testSuiteId, onCancel }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const { setFieldsValue, validateFields, resetFields, getFieldError } = form;

  useEffect(() => {
    if (testSuiteId) {
      dispatch(getTestSuiteDetail({ id: testSuiteId }));
      dispatch(getTestCases({ id: testSuiteId }));
    }
  }, [dispatch, testSuiteId]);

  const data = useSelector(currentTestSuiteSelector);
  const { projectId } = data;
  const testCasesByTestSuiteIdFilter = useSelector(
    testCasesByTestSuiteIdSelector
  );
  const testCases = testCasesByTestSuiteIdFilter(testSuiteId);
  const testCasesLoadingByTestSuiteIdFilter = useSelector(
    testCasesLoadingByTestSuiteIdSelector
  );
  const testCasesLoading = testCasesLoadingByTestSuiteIdFilter(testSuiteId);
  const loading = useSelector(jobLoadingSelector);
  const clientIdsFilter = useSelector(connectedClientIdsFilterSelector);
  const connectedClientIds = clientIdsFilter();

  const {
    platformName,
    deviceType: _deviceType,
    target,
    fileId: _fileId,
    bundleId: _bundleId,
    appPackage: _appPackage,
    appActivity: _appActivity,
    teamId,
  } = data;

  const [fileId, setFileId] = useState(_fileId);

  useEffect(() => {
    if (_fileId) {
      setFileId(_fileId);
    }
  }, [_fileId]);

  const [deviceType, setDeviceType] = useState(_deviceType);

  useEffect(() => {
    if (_deviceType) {
      setDeviceType(_deviceType);
    }
  }, [_deviceType]);

  const [bundleId, setBundleId] = useState(_bundleId);

  useEffect(() => {
    if (_bundleId) {
      setBundleId(_bundleId);
    }
  }, [_bundleId]);

  const [appPackage, setAppPackage] = useState(_appPackage);

  useEffect(() => {
    if (_appPackage) {
      setAppPackage(_appPackage);
    }
  }, [_appPackage]);

  const [appActivity, setAppActivity] = useState(_appActivity);

  useEffect(() => {
    if (_appActivity) {
      setAppActivity(_appActivity);
    }
  }, [_appActivity]);

  const [currentStep, setCurrentStep] = useState(0);
  const [currentStepStatus, setCurrentStepStatus] = useState('process');

  useEffect(() => {
    dispatch(getAppleTeams());
  }, [dispatch]);

  const appleTeams = useSelector(appleTeamsSelector);

  const handleChangeStep = useCallback(
    async (step) => {
      try {
        await validateFields();
        setCurrentStep(step);
        setCurrentStepStatus('process');
      } catch (error) {
        if (step > 0 && getFieldError('name').length > 0) {
          setCurrentStep(0);
          setCurrentStepStatus('error');
        } else if (step > 1 && getFieldError('deviceType').length > 0) {
          setCurrentStep(1);
          setCurrentStepStatus('error');
        } else if (step > 1 && getFieldError('teamId').length > 0) {
          setCurrentStep(1);
          setCurrentStepStatus('error');
        } else if (
          step > 2 &&
          (getFieldError('fileId').length > 0 ||
            getFieldError('bundleId').length > 0 ||
            getFieldError('appActivity').length > 0 ||
            getFieldError('appPackage').length > 0)
        ) {
          setCurrentStep(2);
          setCurrentStepStatus('error');
        } else if (step > 3 && getFieldError('testCaseIds').length > 0) {
          setCurrentStep(3);
          setCurrentStepStatus('error');
        }
      }
    },
    [getFieldError, validateFields]
  );

  const handleCancel = useCallback(() => {
    resetFields();
    onCancel();
    // Reset state:
    setCurrentStep(0);
    setCurrentStepStatus('process');
  }, [onCancel, resetFields]);

  const handleSubmit = useCallback(async () => {
    try {
      const values = await validateFields();
      delete values.email;
      const payload = { testSuiteId, platformName, target, ...values };
      await dispatch(createJob(payload));
      handleCancel();
    } catch (error) {
      handleChangeStep(999);
      // TODO: Do nothing
    }
  }, [
    dispatch,
    handleCancel,
    handleChangeStep,
    platformName,
    target,
    testSuiteId,
    validateFields,
  ]);

  const renderStep0 = () => (
    <Step title={t('jobInfo')} description={<TestSuiteInfoSection />} />
  );

  const handleChangeDeviceType = useCallback(
    (value) => {
      setDeviceType(value);
      setFieldsValue({ fileId: null });
      // Need to refresh selected id in App Storage
      setFileId(null);
    },
    [setFieldsValue]
  );

  const renderStep1 = () => (
    <Step
      title={t('platform')}
      description={
        <TestSuitePlatformEditionSection
          platformName={platformName}
          target={target}
          deviceType={deviceType}
          onChangeDeviceType={handleChangeDeviceType}
          appleTeams={appleTeams}
          teamId={teamId}
          onSetFieldsValue={setFieldsValue}
        />
      }
    />
  );

  const handleSelectFile = useCallback(
    (_id) => {
      setFieldsValue({ fileId: _id });
    },
    [setFieldsValue]
  );

  const handleChangeAppType = useCallback(
    (type) => {
      if (type === 'fileId') {
        setFieldsValue({ bundleId: null, appPackage: null, appActivity: null });
      } else if (type === 'installedApp') {
        setFieldsValue({ fileId: null });
      }
    },
    [setFieldsValue]
  );

  const renderStep2 = () => (
    <Step
      title={t('appType')}
      description={
        <TestSuiteConfigSection
          projectId={projectId}
          platformName={platformName}
          deviceType={deviceType}
          fileId={fileId}
          bundleId={bundleId}
          appPackage={appPackage}
          appActivity={appActivity}
          onSelectFile={handleSelectFile}
          onChangeAppType={handleChangeAppType}
          onSetFieldsValue={setFieldsValue}
        />
      }
    />
  );

  const renderStep3 = () => (
    <Step
      title={t('selectTestCase')}
      description={
        <TestCasesSelectionSection
          {...{
            data: testCases,
            loading: testCasesLoading,
            setFieldsValue,
          }}
        />
      }
    />
  );

  const renderStep4 = () => (
    <Step
      title={t('executePeriod')}
      description={
        <ScheduleSection {...{ connectedClientIds, setFieldsValue }} />
      }
    />
  );

  const renderStep5 = () => (
    <Step
      title={t('notifyAfterBuild')}
      description={<NotificationSection form={form} />}
    />
  );

  return (
    <SharedDrawer
      visible={visible}
      width={720}
      title={t('createJob')}
      okText={t('create')}
      cancelText={t('cancel')}
      onCancel={handleCancel}
      onSubmit={handleSubmit}
      submitLoading={loading}
      destroyOnClose
    >
      <Form form={form} layout="vertical">
        <Steps
          current={currentStep}
          onChange={handleChangeStep}
          direction="vertical"
          status={currentStepStatus}
          style={{ marginBottom: 50 }}
        >
          {renderStep0()}
          {renderStep1()}
          {renderStep2()}
          {renderStep3()}
          {renderStep4()}
          {renderStep5()}
        </Steps>
      </Form>
    </SharedDrawer>
  );
}

JobCreationDrawer.propTypes = {
  visible: PropTypes.bool,
  onCancel: PropTypes.func,
  testSuiteId: PropTypes.string,
};
