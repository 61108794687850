/* eslint-disable react/no-unescaped-entities */
/* eslint-disable max-len */
import React from 'react';
import { Typography } from 'antd';
import useScrollToHash from 'hooks/useScrollToHash';
import { Trans } from 'gatsby-plugin-react-i18next';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';

const { Title, Paragraph, Text } = Typography;

function IntroductionDoc() {
  useScrollToHash();

  const data = useStaticQuery(graphql`
    query {
      moppiumFlow: file(relativePath: { eq: "tutorial/moppium-flow.png" }) {
        childImageSharp {
          fluid(maxWidth: 1024) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <>
      <Title id="introduction">
        <Trans ns="tutorial" i18nKey="introduction" />
      </Title>
      <Paragraph>
        <Trans
          ns="tutorial"
          i18nKey="moppium-is-a-system-for-generating-continuously-executing-and-managing-android-ios-application-test-cases"
        >
          <Text strong />
        </Trans>
      </Paragraph>
      <Paragraph>
        <Trans
          ns="tutorial"
          i18nKey="the-system-structure-is-shown-in-the-following-image"
        />
        <Img
          fluid={data?.moppiumFlow?.childImageSharp?.fluid}
          style={{ width: '50%' }}
          alt="moppium-flow"
        />
      </Paragraph>
      <Paragraph>
        <Trans
          ns="tutorial"
          i18nKey="by-having-a-grid-function-you-are-able-to-execute-the-test-everywhere-on-any-environment-which-can-be-accessed"
        />
      </Paragraph>
    </>
  );
}

export default React.memo(IntroductionDoc);
