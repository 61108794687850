export const IDLE = 'idle';
export const RUNNING = 'running';
export const FAILURE = 'failure';
export const SUCCESS = 'success';
export const TIMEOUT = 'timeout';
export const COLORS = {
  [RUNNING]: 'blue',
  [FAILURE]: 'red',
  [SUCCESS]: 'green',
  [TIMEOUT]: '#f50',
};
