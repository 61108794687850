import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { List, Empty, Button } from 'antd';
import { getProjects, createProject } from 'redux/Projects/slice';
import SEO from 'components/SEO';
import { useTranslation } from 'react-i18next';

import ActionBar from 'components/ActionBar';
import ProjectFormCreationDrawer from 'components/Projects/ProjectFormCreationDrawer';
import ProjectCard from 'containers/ProjectCard';

import {
  projectsSelector,
  projectLoadingSelector,
} from 'redux/Projects/selectors';
import { SettingOutlined } from '@ant-design/icons';
import { useI18next } from 'gatsby-plugin-react-i18next';
import SharedLayout from 'components/Styled/SharedLayout';

function ProjectsPage() {
  const dispatch = useDispatch();
  const { navigate } = useI18next();
  const { t } = useTranslation();
  const [visible, setVisible] = useState();

  const data = useSelector(projectsSelector);
  const loading = useSelector(projectLoadingSelector);

  const handleGetProjects = useCallback(() => dispatch(getProjects()), [
    dispatch,
  ]);

  const handleCancel = useCallback(() => {
    setVisible(false);
  }, []);

  useEffect(() => {
    handleGetProjects();
  }, [handleGetProjects]);

  const handleShow = () => {
    setVisible(true);
  };

  const handleCreate = useCallback(
    async (values) => {
      await dispatch(createProject(values));
    },
    [dispatch]
  );

  const handleCreateProject = () => {
    handleShow();
  };

  const emptyText = (
    <Empty description={<span>{t('noProject')}</span>}>
      <Button type="primary" onClick={handleShow}>
        {t('createNow')}
      </Button>
    </Empty>
  );

  const handlePushSetting = () => {
    navigate('/dashboard/settings');
  };

  const renderExpandActions = () => (
    <Button
      type="primary"
      size="large"
      icon={<SettingOutlined />}
      style={{ marginLeft: 10 }}
      onClick={handlePushSetting}
    >
      {t('settings')}
    </Button>
  );

  return (
    <SharedLayout>
      <SEO title={t('projectsList')} description={t('SEODescription')} />
      <ActionBar
        title={t('projectList')}
        createTitle={t('createProject')}
        onCreate={handleCreateProject}
        renderExpandActions={renderExpandActions}
      />
      <List
        locale={{ emptyText }}
        loading={loading}
        itemLayout="horizontal"
        dataSource={data}
        rowKey="id"
        renderItem={(item) => <ProjectCard data={item} />}
        pagination={{ pageSize: 5 }}
      />
      <ProjectFormCreationDrawer
        visible={visible}
        onCancel={handleCancel}
        onSubmit={handleCreate}
      />
    </SharedLayout>
  );
}

export default React.memo(ProjectsPage);
