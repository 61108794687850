import { memoize } from 'lodash';
import { dataSelectorFactory, containerSelectorFactory } from 'redux/selectors';
import { createSelector } from 'reselect';

const KEY = 'testCaseResults';

export const testCaseResultsFilterSelector = createSelector(
  dataSelectorFactory(KEY),
  containerSelectorFactory(KEY),
  (data, containers) =>
    memoize((deviceResultId) => {
      const ids = containers[deviceResultId];
      if (ids) {
        return ids.map((id) => data[id]);
      }
      return [];
    })
);
