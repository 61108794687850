import React from 'react';
import { Empty, Button, List } from 'antd';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import isEqual from 'react-fast-compare';
import TestCaseItem from './TestCaseItem';

function TestCaseList({ onShowTestCaseCreationForm, loading, data, span }) {
  const { t } = useTranslation();

  const empty = (
    <Empty description={<span>{t('noTestCases')}</span>}>
      <Button type="primary" onClick={onShowTestCaseCreationForm}>
        {t('createNow')}
      </Button>
    </Empty>
  );

  return (
    <List
      grid={{ gutter: 16, column: 2 }}
      pagination={{ pageSize: 4 }}
      locale={{ emptyText: empty }}
      loading={loading}
      itemLayout="horizontal"
      dataSource={data}
      renderItem={(item) => (
        <TestCaseItem span={span} key={item.id} item={item} />
      )}
    />
  );
}

TestCaseList.propTypes = {
  onShowTestCaseCreationForm: PropTypes.func,
  loading: PropTypes.bool,
  data: PropTypes.array,
  span: PropTypes.number,
};

TestCaseList.defaultProps = {
  data: [],
  span: 24,
};

export default React.memo(TestCaseList, isEqual);
