import React from 'react';
import { Location, Router } from '@reach/router';
import PropTypes from 'prop-types';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

// TODO: Fix router component is div issue
// https://github.com/reach/router/issues/63#issuecomment-524297867
function RouterComponent({ children }) {
  return <>{children}</>;
}

RouterComponent.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

const FadeTransitionRouter = ({ children, basepath }) => (
  <Location>
    {({ location }) => (
      <TransitionGroup style={{ flex: 1 }} className="transition-group">
        <CSSTransition key={location.key} classNames="zoom" timeout={0}>
          <Router
            basepath={basepath}
            location={location}
            style={{ flex: 1, height: '100%' }}
          >
            {children}
          </Router>
        </CSSTransition>
      </TransitionGroup>
    )}
  </Location>
);

FadeTransitionRouter.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  basepath: PropTypes.string,
};

export default FadeTransitionRouter;
