import React, { memo, useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Dropdown, Layout, Menu, Avatar } from 'antd';
import { graphql, useStaticQuery } from 'gatsby';
import Cookies from 'universal-cookie';
import { useDispatch } from 'react-redux';
import {
  useI18next,
  I18nextContext,
  useTranslation,
} from 'gatsby-plugin-react-i18next';
import { locale } from 'moment';
import { signOut } from 'redux/Auth/slice';
import {
  CaretDownOutlined,
  CheckCircleOutlined,
  UserOutlined,
} from '@ant-design/icons';
import ClientStatus from 'containers/ClientStatus';
import isEqual from 'react-fast-compare';
import ContactUs from 'components/ContactUs';
// TODO: Reason why you should import locale
// https://create-react-app.dev/docs/troubleshooting/#momentjs-locales-are-missing
// import 'moment/locale/ja';
import { version } from '../../../package.json';

const { Header } = Layout;
const { SubMenu } = Menu;

const cookies = new Cookies();

export const LANGUAGES = [
  { key: 'en', value: 'English' },
  { key: 'ja', value: '日本語' },
];

// TODO: SETUP CHANGE THEME START
// export const THEMES = [
//   { key: 'light', value: 'Light' },
//   { key: 'dark', value: 'Dark' },
// ];
// TODO: SETUP CHANGE THEME END

const MENU = [
  {
    key: '/dashboard/projects',
    name: 'projects',
  },
  {
    key: '/dashboard/tutorial',
    name: 'tutorial',
  },
];

const SIGN_OUT = 'sign-out';

const SharedHeader = ({ location }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [selectedTab, setSelectedTab] = useState(MENU[0].key);
  const { changeLanguage, navigate } = useI18next();
  const { language } = React.useContext(I18nextContext);
  const username = cookies.get('username');

  useEffect(() => {
    locale(language);
  }, [language]);

  const handleClickTab = useCallback(
    ({ key }) => {
      navigate(key);
    },
    [navigate]
  );

  const handleClickDropDown = useCallback(
    ({ key }) => {
      if (key === SIGN_OUT) {
        dispatch(signOut());
      }
    },
    [dispatch]
  );

  const { pathname } = location;

  if (!pathname.includes(selectedTab)) {
    for (const { key } of MENU) {
      if (pathname.includes(key)) {
        setSelectedTab(key);
        break;
      }
    }
  }

  const handleLanguage = useCallback(
    (e) => {
      changeLanguage(e.key);
      locale(e.key);
    },
    [changeLanguage]
  );

  // TODO: SETUP CHANGE THEME START
  // const handleTheme = ({ key }) => {
  //   changeTheme(key);
  // };

  // TODO: SETUP CHANGE THEME END
  const dropdown = (
    <Menu onClick={handleClickDropDown}>
      <Menu.Item>{username}</Menu.Item>
      <SubMenu title={t('selectLang')} onClick={handleLanguage}>
        {LANGUAGES.map(({ key, value }) => (
          <Menu.Item key={key}>
            {key === language && <CheckCircleOutlined />}
            {value}
          </Menu.Item>
        ))}
      </SubMenu>
      {/* TODO: SETUP CHANGE THEME START */}
      {/* <SubMenu title="Themes" onClick={handleTheme}>
        {THEMES.map(({ key, value }) => (
          <Menu.Item key={key}>{value}</Menu.Item>
        ))}
      </SubMenu> */}
      {/* TODO: SETUP CHANGE THEME END */}
      <Menu.Item danger key={SIGN_OUT}>
        {t('signOut')}
      </Menu.Item>
      <Menu.Item disabled>
        {t('version')}: {version}
      </Menu.Item>
    </Menu>
  );

  const images = useStaticQuery(graphql`
    query {
      logo: file(relativePath: { eq: "logo/moppium_horizontal_375x100.png" }) {
        childImageSharp {
          fluid(maxWidth: 480) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <Header theme="dark">
      <div className="logo-container">
        <img
          alt="logo"
          src={images?.logo?.childImageSharp?.fluid?.src}
          width="120"
          height="32"
        />
      </div>
      <div
        style={{
          margin: 16,
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
        }}
      >
        <ClientStatus />
        <Menu
          selectedKeys={[selectedTab]}
          mode="horizontal"
          style={{ lineHeight: '64px', margin: '0px 20px' }}
          onClick={handleClickTab}
        >
          {MENU.map(({ key, name }) => (
            <Menu.Item key={key}>
              <span to={key}>{t(name)}</span>
            </Menu.Item>
          ))}
        </Menu>
        <ContactUs size={40} style={{ marginRight: 30 }} />
        <Dropdown overlay={dropdown} trigger={['click']}>
          <a>
            <Avatar
              icon={<UserOutlined />}
              style={{ color: '#1DA57A', backgroundColor: '#fde3cf' }}
            />
            {'   '}
            <CaretDownOutlined />
          </a>
        </Dropdown>
      </div>
    </Header>
  );
};

SharedHeader.propTypes = {
  location: PropTypes.object.isRequired,
};

export default memo(SharedHeader, isEqual);
