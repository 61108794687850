import React, { useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { createFile, getFiles, deleteFile } from 'redux/Files/slice';
import { filesSelector, fileLoadingSelector } from 'redux/Files/selector';
import AppFileList from 'components/AppStorage/AppFileList';
import AppUploader from 'components/AppStorage/AppUploader';

export default function AppStorage({
  projectId,
  selectedFileId,
  hideSelectType,
  fileType,
  column,
  pageSize,
  onSelect,
}) {
  const dispatch = useDispatch();

  useEffect(() => {
    if (projectId) {
      dispatch(getFiles({ id: projectId, type: fileType, $refresh: true }));
    }
  }, [dispatch, fileType, projectId]);

  const handleCreateFile = useCallback(
    (payload) => {
      dispatch(createFile(payload));
    },
    [dispatch]
  );

  const handleSearchFile = useCallback(
    (value, type) => {
      dispatch(
        getFiles({
          id: projectId,
          search: value,
          type,
          $refresh: true,
        })
      );
    },
    [dispatch, projectId]
  );

  const handleDelete = useCallback(
    (id) => {
      dispatch(deleteFile({ id }));
    },
    [dispatch]
  );

  const filesFilter = useSelector(filesSelector);
  const data = filesFilter(fileType);
  const loading = useSelector(fileLoadingSelector);

  return (
    <>
      <AppUploader projectId={projectId} onCreateFile={handleCreateFile} />
      <AppFileList
        data={data}
        loading={loading}
        onSearch={handleSearchFile}
        onDelete={handleDelete}
        hideSelectType={hideSelectType}
        column={column}
        pageSize={pageSize}
        onSelect={onSelect}
        selectedFileId={selectedFileId}
      />
    </>
  );
}

AppStorage.propTypes = {
  projectId: PropTypes.string,
  selectedFileId: PropTypes.string,
  hideSelectType: PropTypes.bool,
  fileType: PropTypes.string,
  column: PropTypes.number,
  pageSize: PropTypes.number,
  onSelect: PropTypes.func,
};

AppStorage.defaultProps = {
  pageSize: 10,
};
