/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable max-len */
import React from 'react';
import { Typography, Alert, Button } from 'antd';
import useScrollToHash from 'hooks/useScrollToHash';
import { Trans } from 'gatsby-plugin-react-i18next';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import {
  CaretUpOutlined,
  CloseOutlined,
  PlusOutlined,
} from '@ant-design/icons';

const { Title, Paragraph, Text } = Typography;

function CreateTestCases() {
  useScrollToHash();

  const data = useStaticQuery(graphql`
    query {
      projectList1: file(relativePath: { eq: "tutorial/project-list-1.png" }) {
        childImageSharp {
          fluid(maxWidth: 1024) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      projectList2: file(relativePath: { eq: "tutorial/project-list-2.png" }) {
        childImageSharp {
          fluid(maxWidth: 1024) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      testSuitePlatform: file(
        relativePath: { eq: "tutorial/test-suite-platform.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1024) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      devicesManagement: file(
        relativePath: { eq: "tutorial/devices-management.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1024) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      projectDetail: file(
        relativePath: { eq: "tutorial/project-detail-1.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1024) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      projectDetail2: file(
        relativePath: { eq: "tutorial/project-detail-2.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1024) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      testSuiteDetail: file(
        relativePath: { eq: "tutorial/test-suite-detail-1.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1024) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      testSuiteDetail2: file(
        relativePath: { eq: "tutorial/test-suite-detail-2.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1024) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      testCaseDetail: file(
        relativePath: { eq: "tutorial/test-case-detail.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1024) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      inspector1: file(relativePath: { eq: "tutorial/inspector-1.png" }) {
        childImageSharp {
          fluid(maxWidth: 1024) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      inspector2: file(relativePath: { eq: "tutorial/inspector-2.png" }) {
        childImageSharp {
          fluid(maxWidth: 1024) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      appPackageAndActivity: file(
        relativePath: { eq: "tutorial/app-package-and-activity.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1024) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <>
      <Title level={2} id="create-test-cases">
        <Trans ns="tutorial" i18nKey="create-test-cases" />
      </Title>
      <Title level={3} id="create-project">
        <Trans ns="tutorial" i18nKey="create-project" />
      </Title>
      <Paragraph>
        <Trans
          ns="tutorial"
          i18nKey="from-the-project-list-screen-click-the-create-now-button-or-the-plus-button-in-the-upper-right-to-create-a-new-project"
        >
          <Text strong />
          <Button type="primary" size="small" />
          <Button
            type="primary"
            size="small"
            icon={<PlusOutlined />}
            shape="circle"
          />
        </Trans>
      </Paragraph>
      <Img
        fluid={data?.projectList1?.childImageSharp?.fluid}
        style={{ width: '70%' }}
        alt="project-list-1"
      />
      <Title level={3} id="create-test-suite">
        <Trans ns="tutorial" i18nKey="create-test-suite" />
      </Title>
      <Paragraph>
        <Trans
          ns="tutorial"
          i18nKey="from-the-project-list-screen-click-see-more-on-the-project-you-want-to-use"
        >
          <Text strong />
          <Button size="small" type="link" style={{ padding: '4px 0px' }} />
        </Trans>
      </Paragraph>
      <Img
        fluid={data?.projectList2?.childImageSharp?.fluid}
        style={{ width: '70%' }}
        alt="project-list-2"
      />
      <Paragraph>
        <Trans
          ns="tutorial"
          i18nKey="from-the-project-details-screen-click-the-create-now-button-or-the-plus-button-in-the-upper-right-to-create-a-test-suite"
        >
          <Text strong />
          <Button type="primary" size="small" />
          <Button
            type="primary"
            size="small"
            icon={<PlusOutlined />}
            shape="circle"
          />
        </Trans>
      </Paragraph>
      <Img
        fluid={data?.projectDetail?.childImageSharp?.fluid}
        style={{ width: '70%' }}
        alt="project-detail-1"
      />
      <Paragraph>
        <ol>
          <li>
            <Text strong>
              <Trans ns="tutorial" i18nKey="test-suite-information" />
              {': '}
            </Text>
            <Trans
              ns="tutorial"
              i18nKey="enter-the-test-suite-name-and-description"
            />
          </li>
          <li>
            <Text strong>
              <Trans ns="tutorial" i18nKey="platform" />
            </Text>
            <ul>
              <li>
                <Trans
                  ns="tutorial"
                  i18nKey="select-the-device-you-want-to-test"
                />
                <Img
                  fluid={data?.testSuitePlatform?.childImageSharp?.fluid}
                  style={{ width: '70%' }}
                  alt="test-suite-platform"
                />
              </li>
              <li>
                <Trans ns="tutorial" i18nKey="select-application-type" />
                <ul>
                  <li>
                    <Trans
                      ns="tutorial"
                      i18nKey="case-of-using-an-installed-app"
                    />
                    <ul>
                      <li>
                        <Text code>
                          <Trans ns="tutorial" i18nKey="ios" />
                        </Text>
                        {': '}
                        <Trans ns="tutorial" i18nKey="enter-the-bundle-id" />
                      </li>
                      <li>
                        <Text code>
                          <Trans ns="tutorial" i18nKey="android" />
                        </Text>
                        {': '}
                        <Trans
                          ns="tutorial"
                          i18nKey="enter-the-app-activity-and-app-package"
                        />

                        <div id="how-to-find-app-package-and-app-activity-of-your-android-app" />
                        <Alert
                          showIcon
                          type="info"
                          style={{ marginTop: 10 }}
                          message={
                            <Trans
                              ns="tutorial"
                              i18nKey="how-to-find-app-package-and-app-activity-of-your-android-app"
                            />
                          }
                          description={
                            <Typography>
                              <Paragraph>
                                <Trans
                                  ns="tutorial"
                                  i18nKey="connect-the-real-device-or-emulator-to-the-pc-and-start-the-application-enter-the-command-shown-below-to-get-the-application-package-name-application-activity-name-of-the-currently-open-application"
                                />
                              </Paragraph>
                              <Paragraph>
                                <Text strong>
                                  [
                                  <Trans
                                    ns="tutorial"
                                    i18nKey="for-mac-linux"
                                  />
                                  ]
                                </Text>
                                <br />
                                <Text code copyable>
                                  adb shell dumpsys window | grep -E
                                  'mCurrentFocus'
                                </Text>
                              </Paragraph>
                              <Paragraph>
                                <Text strong>
                                  [
                                  <Trans ns="tutorial" i18nKey="for-windows" />]
                                </Text>
                                <br />
                                <Text code copyable>
                                  adb shell dumpsys window | find
                                  "mCurrentFocus"
                                </Text>
                              </Paragraph>
                              <Img
                                fluid={
                                  data?.appPackageAndActivity?.childImageSharp
                                    ?.fluid
                                }
                                style={{ width: '80%' }}
                                alt="app-package-and-activity"
                              />
                            </Typography>
                          }
                        />
                      </li>
                    </ul>
                  </li>
                  <li>
                    <Trans ns="tutorial" i18nKey="case-of-uploading-app-file" />
                    <ul>
                      <li>
                        <Text code>
                          <Trans ns="tutorial" i18nKey="ios" />
                        </Text>
                        <ul>
                          <li>
                            <Text code>
                              <Trans ns="tutorial" i18nKey="simulator" />
                            </Text>
                            {': '}
                            <Trans
                              ns="tutorial"
                              i18nKey="upload-the-zipped-fileapp"
                            />
                          </li>
                          <li>
                            <Text code>
                              <Trans ns="tutorial" i18nKey="real-device" />
                            </Text>
                            {': '}
                            <Trans
                              ns="tutorial"
                              i18nKey="upload-the-zipped-fileipa"
                            />
                          </li>
                        </ul>
                      </li>
                      <li>
                        <Text code>
                          <Trans ns="tutorial" i18nKey="android" />
                        </Text>
                        {': '}
                        <Trans
                          ns="tutorial"
                          i18nKey="upload-the-zipped-fileapk"
                        />
                      </li>
                    </ul>
                  </li>
                </ul>
              </li>
            </ul>
          </li>
        </ol>
      </Paragraph>
      <Title level={3} id="create-test-case">
        <Trans ns="tutorial" i18nKey="create-test-case" />
      </Title>
      <Paragraph>
        <Trans
          ns="tutorial"
          i18nKey="on-the-project-details-screen-click-see-details-of-the-created-test-suite"
        >
          <Text strong />
          <Button type="link" size="small" style={{ padding: '4px 0px' }} />
        </Trans>
      </Paragraph>
      <Img
        fluid={data?.projectDetail2?.childImageSharp?.fluid}
        style={{ width: '70%' }}
        alt="project-detail-2"
      />
      <Paragraph>
        <Trans
          ns="tutorial"
          i18nKey="from-the-test-suite-details-screen-click-the-create-now-button-or-the-plus-button-in-the-upper-right-to-create-a-test-case"
        >
          <Text strong />
          <Button type="primary" size="small" />
          <Button
            type="primary"
            size="small"
            icon={<PlusOutlined />}
            shape="circle"
          />
        </Trans>
      </Paragraph>
      <Img
        fluid={data?.testSuiteDetail?.childImageSharp?.fluid}
        style={{ width: '70%' }}
        alt="test-suite-detail-1"
      />
      <Paragraph>
        <Trans
          ns="tutorial"
          i18nKey="click-see-details-of-the-created-test-case-to-actually-create-a-test-script"
        >
          <Button type="link" size="small" style={{ padding: '4px 0px' }} />
        </Trans>
      </Paragraph>
      <Img
        fluid={data?.testSuiteDetail2?.childImageSharp?.fluid}
        style={{ width: '70%' }}
        alt="test-suite-detail-2"
      />
      <Paragraph>
        <Trans
          ns="tutorial"
          i18nKey="click-start-inspector-from-the-upper-right-of-the-test-case-details-screen"
        >
          <Button type="primary" size="small" icon={<CaretUpOutlined />} />
          <Text strong />
        </Trans>
      </Paragraph>
      <Img
        fluid={data?.testCaseDetail?.childImageSharp?.fluid}
        style={{ width: '70%' }}
        alt="test-case-detail"
      />
      <Paragraph>
        <Trans
          ns="tutorial"
          i18nKey="the-connected-devices-to-the-moppium-client-is-displayed-select-the-device-for-which-you-want-to-create-a-test-script-and-click-connect-to-client-device"
        >
          <Button type="primary" size="small" />
        </Trans>
      </Paragraph>
      <Img
        fluid={data?.inspector1?.childImageSharp?.fluid}
        style={{ width: '70%' }}
        alt="inspector-1"
      />
      <Paragraph>
        <Trans
          ns="tutorial"
          i18nKey="in-the-inspector-screen-record-the-operation-want-to-test-to-create-test-case"
        />
      </Paragraph>
      <Paragraph>
        <Trans
          ns="tutorial"
          i18nKey="for-details-on-the-inspector-function-go-to-how-to-use-the-inspector"
        >
          <a href="#how-to-use-inspector" />
        </Trans>
      </Paragraph>
      <br />
      <Paragraph>
        <Trans
          ns="tutorial"
          i18nKey="when-the-test-case-recording-is-complete-save-the-test-action-and-click-the-button-to-exit-the-inspector"
        >
          <Button danger type="primary" size="small" icon={<CloseOutlined />} />
        </Trans>
      </Paragraph>
      <Img
        fluid={data?.inspector2?.childImageSharp?.fluid}
        style={{ width: '70%' }}
        alt="inspector-2"
      />
    </>
  );
}

export default React.memo(CreateTestCases);
