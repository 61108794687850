import React, { useState, useCallback, useEffect } from 'react';
import { Steps, Form } from 'antd';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import SharedDrawer from 'components/SharedDrawer';
import isEqual from 'react-fast-compare';
import { createTestSuite } from 'redux/TestSuites/slice';
import TestSuiteInfoSection from 'components/TestSuites/TestSuiteInfoSection';
import TestSuitePlatformSelectionSection from 'components/TestSuites/TestSuitePlatformSelectionSection';
import TestSuiteConfigSection from 'components/TestSuites/TestSuiteConfigSection';
import { useDispatch, useSelector } from 'react-redux';
import { getAppleTeams } from 'redux/AppleTeams/slice';
import { appleTeamsSelector } from 'redux/AppleTeams/selectors';

const { Step } = Steps;

function TestSuiteCreationDrawer({ projectId, visible, onCancel }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const {
    resetFields,
    validateFields,
    setFieldsValue,
    getFieldValue,
    getFieldError,
  } = form;

  const [currentStep, setCurrentStep] = useState(0);
  const [currentStepStatus, setCurrentStepStatus] = useState('process');
  const [platformName, setPlatformName] = useState();
  const [deviceType, setDeviceType] = useState();
  const [target, setTarget] = useState();

  useEffect(() => {
    dispatch(getAppleTeams());
  }, [dispatch]);

  const appleTeams = useSelector(appleTeamsSelector);

  const handleCancel = useCallback(() => {
    resetFields();
    setCurrentStep(0);
    setCurrentStepStatus('process');
    setPlatformName();
    setDeviceType();
    setTarget();
    onCancel();
  }, [onCancel, resetFields]);

  const handleChangeStep = useCallback(
    (step) => {
      if (!getFieldValue('name')) {
        setCurrentStep(0);
        setCurrentStepStatus('error');
      } else if (step > 1 && getFieldError('target').length > 0) {
        setCurrentStep(1);
        setCurrentStepStatus('error');
      } else if (step > 1 && getFieldError('teamId').length > 0) {
        setCurrentStep(1);
        setCurrentStepStatus('error');
      } else if (
        step > 1 &&
        (getFieldError('fileId').length > 0 ||
          getFieldError('bundleId').length > 0 ||
          getFieldError('appActivity').length > 0 ||
          getFieldError('appPackage').length > 0)
      ) {
        setCurrentStep(1);
        setCurrentStepStatus('error');
      } else if (step > 2 && getFieldError('clientId').length > 0) {
        setCurrentStep(2);
        setCurrentStepStatus('error');
      } else {
        setCurrentStep(step);
        setCurrentStepStatus('process');
      }
    },
    [getFieldError, getFieldValue]
  );

  const handleSubmit = useCallback(async () => {
    try {
      const values = await validateFields();
      await dispatch(createTestSuite({ id: projectId, ...values }));
      handleCancel();
    } catch (error) {
      handleChangeStep(999);
    }
  }, [dispatch, handleCancel, handleChangeStep, projectId, validateFields]);

  const renderStep0 = () => (
    <Step title={t('testSuiteInfo')} description={<TestSuiteInfoSection />} />
  );

  const handleChangePlatform = useCallback(
    ([_platformName, _deviceType, _target]) => {
      setPlatformName(_platformName);
      setDeviceType(_deviceType);
      setTarget(_target);
    },
    []
  );

  const handleSelectFile = useCallback(
    (_id) => {
      setFieldsValue({ fileId: _id });
    },
    [setFieldsValue]
  );

  const renderStep1 = () => (
    <Step
      title={t('platform')}
      description={
        <>
          <TestSuitePlatformSelectionSection
            onSetFieldsValue={setFieldsValue}
            onChangePlaftorm={handleChangePlatform}
            appleTeams={appleTeams}
          />
          <div style={{ marginTop: 20 }} />
          {target === 'app' && (
            <TestSuiteConfigSection
              projectId={projectId}
              platformName={platformName}
              deviceType={deviceType}
              onSelectFile={handleSelectFile}
              onSetFieldsValue={setFieldsValue}
            />
          )}
        </>
      }
    />
  );

  return (
    <SharedDrawer
      visible={visible}
      title={t('createTestSuite')}
      okText={t('create')}
      cancelText={t('cancel')}
      onCancel={handleCancel}
      onSubmit={handleSubmit}
      destroyOnClose
    >
      <Form form={form} layout="vertical">
        <Steps
          current={currentStep}
          onChange={handleChangeStep}
          direction="vertical"
          status={currentStepStatus}
          style={{ marginBottom: 50 }}
        >
          {renderStep0()}
          {renderStep1()}
        </Steps>
      </Form>
    </SharedDrawer>
  );
}

TestSuiteCreationDrawer.propTypes = {
  visible: PropTypes.bool,
  projectId: PropTypes.string.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default React.memo(TestSuiteCreationDrawer, isEqual);
