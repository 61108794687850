export const parseCascaderOptions = (options, renderLabel) =>
  options.map((option) => {
    const tempOption = { ...option, label: renderLabel(option.label) };
    if (option.children) {
      return {
        ...tempOption,
        children: parseCascaderOptions(option.children, renderLabel),
      };
    }
    return tempOption;
  });
