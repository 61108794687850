import React, { useState, useCallback, useEffect } from 'react';
import { Input, Table, Form } from 'antd';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

function TestCasesSelectionSection({
  data,
  loadingTestCases,
  testCaseIds,
  setFieldsValue,
}) {
  const { t } = useTranslation();
  const [selectedIds, setSelectedIds] = useState(testCaseIds);
  useEffect(() => {
    setFieldsValue({ testCaseIds });
    setSelectedIds(testCaseIds);
  }, [setFieldsValue, testCaseIds]);

  const testCaseColumns = [
    {
      title: t('name'),
      dataIndex: 'name',
    },
  ];

  const handleChange = useCallback(
    (selectedRowKeys) => {
      setFieldsValue({ testCaseIds: selectedRowKeys });
      setSelectedIds(selectedRowKeys);
    },
    [setFieldsValue]
  );

  return (
    <Form.Item>
      <Form.Item
        name="testCaseIds"
        initialValue={testCaseIds || []}
        rules={[
          {
            required: true,
            message: t('selectTestCase'),
          },
        ]}
        noStyle
      >
        <Input style={{ display: 'none' }} onChange={handleChange} />
      </Form.Item>
      <Table
        rowSelection={{
          type: 'checkbox',
          onChange: handleChange,
          selectedRowKeys: selectedIds,
        }}
        columns={testCaseColumns}
        dataSource={data}
        loading={loadingTestCases}
        rowKey="id"
      />
    </Form.Item>
  );
}

TestCasesSelectionSection.propTypes = {
  setFieldsValue: PropTypes.func.isRequired,
  data: PropTypes.array,
  loadingTestCases: PropTypes.bool,
  testCaseIds: PropTypes.array,
};

TestCasesSelectionSection.defaultProps = {
  testCaseIds: [],
};

export default TestCasesSelectionSection;
