import React, { useCallback } from 'react';
import { Form } from 'antd';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import SharedDrawer from 'components/SharedDrawer';
import isEqual from 'react-fast-compare';
import SimpleForm from 'components/Forms/SimpleForm';

function ProjectFormEditionDrawer({ visible, onCancel, onSubmit, data }) {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const { validateFields, resetFields } = form;
  const { id, name, description } = data;

  const handleCancel = useCallback(() => {
    resetFields();
    onCancel();
  }, [onCancel, resetFields]);

  const handleSubmit = useCallback(async () => {
    try {
      const values = await validateFields();
      onSubmit({ id, ...values });
      handleCancel();
    } catch (error) {
      // Do nothing
    }
  }, [handleCancel, id, onSubmit, validateFields]);

  return (
    <SharedDrawer
      visible={visible}
      title={t('editProject')}
      okText={t('update')}
      cancelText={t('cancel')}
      onCancel={handleCancel}
      onSubmit={handleSubmit}
    >
      <SimpleForm {...{ form, name, description }} />
    </SharedDrawer>
  );
}

ProjectFormEditionDrawer.propTypes = {
  visible: PropTypes.bool,
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  data: PropTypes.object,
};

ProjectFormEditionDrawer.defaultProps = {
  data: {},
};

export default React.memo(ProjectFormEditionDrawer, isEqual);
