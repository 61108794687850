/* eslint-disable react/no-unescaped-entities */
/* eslint-disable max-len */
import React from 'react';
import SEO from 'components/SEO';
import { useTranslation } from 'react-i18next';
import DocMenu from './DocMenu';
import IntroductionDoc from './IntroductionDoc';
import SetupMoppiumClient from './SetupMoppiumClient';
import CreateTestCases from './CreateTestCases';
import CreateAndExecuteJob from './CreateAndExecuteJob';
import HowToUseInspector from './HowToUseInspector';
import './index.css';

function Docs() {
  const { t } = useTranslation();

  return (
    <div
      style={{
        flex: 1,
        display: 'flex',
        flexDirection: 'row',
        height: '100%',
      }}
      id="tutorial"
    >
      <SEO title={t('tutorial')} description={t('SEODescription')} />
      <DocMenu />
      <div id="right">
        {[
          {
            key: 'IntroductionDoc',
            component: <IntroductionDoc />,
          },
          {
            key: 'SetupMoppiumClient',
            component: <SetupMoppiumClient />,
          },
          {
            key: 'CreateTestCases',
            component: <CreateTestCases />,
          },
          {
            key: 'CreateAndExecuteJob',
            component: <CreateAndExecuteJob />,
          },
          {
            key: 'HowToUseInspector',
            component: <HowToUseInspector />,
          },
        ].map(({ key, component }) => (
          <React.Fragment key={key}>{component}</React.Fragment>
        ))}
      </div>
    </div>
  );
}

export default React.memo(Docs);
