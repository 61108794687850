import React from 'react';
import { Typography } from 'antd';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { testCaseFilterSelector } from 'redux/TestCases/selectors';

const { Text } = Typography;

const TestCaseNameCell = ({ testCaseId }) => {
  const testCaseFilter = useSelector(testCaseFilterSelector);
  const testCase = testCaseFilter(testCaseId);
  return <Text>{testCase.name}</Text>;
};

TestCaseNameCell.propTypes = {
  testCaseId: PropTypes.string,
};

export default TestCaseNameCell;
