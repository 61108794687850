import React from 'react';
import PropTypes from 'prop-types';
import './index.less';

export default function Label({ children }) {
  return <span className="normal-label">{children}</span>;
}

Label.propTypes = {
  children: PropTypes.node.isRequired,
};
