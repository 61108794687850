import React from 'react';
import { DownloadOutlined } from '@ant-design/icons';
import { Button, Typography } from 'antd';
import SEO from 'components/SEO';
import { useI18next } from 'gatsby-plugin-react-i18next';
import { version } from '../../package.json';
import './DownloadPage.css';

const { Title, Text } = Typography;

const download = (url) => async () => {
  const element = document.createElement('a');
  element.setAttribute('href', url);
  element.style.display = 'none';
  document.body.appendChild(element);
  element.click();
};

export default function DownloadPage() {
  const { t } = useI18next();
  return (
    <div
      style={{
        textAlign: 'center',
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
      }}
    >
      <SEO
        title={t('downloadMoppiumClient')}
        description={t('SEODescription')}
      />
      <div style={{ marginBottom: 60 }}>
        <Title>{t('downloadClient')}</Title>
        <Text>{t('downloadClientDescription')}</Text>
      </div>

      <div className="download-container">
        {[
          {
            id: 1,
            src: 'https://code.visualstudio.com/assets/images/windows-logo.png',
            os: 'Windows',
            comingSoon: true,
          },
          {
            id: 2,
            src: 'https://code.visualstudio.com/assets/images/apple-logo.svg',
            os: 'macOS',
            s3: `https://${process.env.GATSBY_ASSETS_BUCKET}.s3-ap-northeast-1.amazonaws.com/public/releases/Moppium+Client-${version}.dmg`,
          },
          {
            id: 3,
            src: 'https://code.visualstudio.com/assets/images/linux-logo.png',
            os: 'Linux',
            comingSoon: true,
          },
        ].map((item) => (
          <div key={item.id} className="child">
            <div style={{ height: 140, width: 140 }}>
              <img alt="icon-img" src={item.src} height="100%" />
            </div>
            <Button
              className="btn"
              type="primary"
              icon={<DownloadOutlined />}
              size="large"
              style={{ minWidth: 200 }}
              onClick={download(item.s3)}
              disabled={item.comingSoon}
            >
              {item.os} {item.comingSoon && `(${t('comingSoon')})`}
            </Button>
          </div>
        ))}
      </div>
    </div>
  );
}
