import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Select, Typography, Input, Form } from 'antd';
import { noWhiteSpaceAtStartAndEnd } from 'utils/validation';
import { useTranslation } from 'react-i18next';
import AppStorage from 'containers/AppStorage';

const { Text } = Typography;
const { Option } = Select;

export default function TestSuiteConfigSection({
  projectId,
  platformName,
  deviceType,
  fileId,
  bundleId,
  onSelectFile,
  appPackage,
  appActivity,
  onSetFieldsValue,
}) {
  const { t } = useTranslation();

  const [appType, setAppType] = useState('fileId');

  useEffect(() => {
    if (bundleId || appPackage) {
      setAppType('installedApp');
    }
  }, [appPackage, bundleId, onSetFieldsValue]);

  useEffect(() => {
    if (fileId) {
      setAppType('fileId');
    }
  }, [fileId, onSetFieldsValue]);

  useEffect(() => {
    onSetFieldsValue({ bundleId });
  }, [bundleId, onSetFieldsValue]);

  useEffect(() => {
    onSetFieldsValue({ fileId });
  }, [fileId, onSetFieldsValue]);

  useEffect(() => {
    onSetFieldsValue({ appPackage, appActivity });
  }, [appActivity, appPackage, onSetFieldsValue]);

  let fileType;

  if (platformName === 'ios') {
    if (deviceType === 'device') {
      fileType = 'ipa';
    } else {
      fileType = 'zip';
    }
  } else if (platformName === 'android') {
    fileType = 'apk';
  }

  const handleChangeAppType = (type) => {
    setAppType(type);
    if (type === 'fileId') {
      onSetFieldsValue({ bundleId: null, appPackage: null, appActivity: null });
    } else if (type === 'installedApp') {
      onSetFieldsValue({ fileId: null });
    }
  };

  return (
    <div>
      <Form.Item
        initialValue="fileId"
        label={<Text strong>{t('selectAppType')}</Text>}
      >
        <Select
          onChange={handleChangeAppType}
          value={appType}
          defaultValue="fileId"
        >
          <Option value="fileId">{t('uploadFile')}</Option>
          <Option value="installedApp">{t('installedApp')}</Option>
        </Select>
      </Form.Item>
      {appType === 'fileId' && (
        <Form.Item>
          <AppStorage
            projectId={projectId}
            hideSelectType
            fileType={fileType}
            column={2}
            pageSize={4}
            onSelect={onSelectFile}
            selectedFileId={fileId}
          />
          <Form.Item
            noStyle
            name="fileId"
            initialValue={fileId}
            rules={[
              {
                required: true,
                message: t('selectFile'),
              },
            ]}
          >
            <Input style={{ display: 'none' }} />
          </Form.Item>
        </Form.Item>
      )}
      {appType === 'installedApp' && platformName === 'ios' && (
        <Form.Item
          name="bundleId"
          initialValue={bundleId}
          rules={[
            {
              required: true,
              message: t('requiredBundleId'),
              whitespace: true,
            },
            {
              max: 155,
              message: t('validate.max', {
                key: 'Bundle Id',
                value: 155,
              }),
            },
            {
              validator: (_, value) => noWhiteSpaceAtStartAndEnd(value, t),
            },
          ]}
        >
          <Input addonBefore="Bundle ID" placeholder="com.example.app" />
        </Form.Item>
      )}
      {appType === 'installedApp' && platformName === 'android' && (
        <>
          <Form.Item
            name="appPackage"
            initialValue={appPackage}
            rules={[
              {
                required: true,
                message: t('requiredAppPackage'),
                whitespace: true,
              },
              {
                max: 155,
                message: t('validate.max', {
                  key: t('appPackage'),
                  value: 155,
                }),
              },
              {
                validator: (_, value) => noWhiteSpaceAtStartAndEnd(value, t),
              },
            ]}
          >
            <Input
              addonBefore={t('appPackage')}
              placeholder="com.google.android.apps.maps"
            />
          </Form.Item>
          <Form.Item
            name="appActivity"
            initialValue={appActivity}
            rules={[
              {
                required: true,
                message: t('requiredAppActivity'),
                whitespace: true,
              },
              {
                max: 155,
                message: t('validate.max', {
                  key: t('appActivity'),
                  value: 155,
                }),
              },
              {
                validator: (_, value) => noWhiteSpaceAtStartAndEnd(value, t),
              },
            ]}
          >
            <Input
              addonBefore={t('appActivity')}
              placeholder="com.google.android.maps.MapsActivity"
            />
          </Form.Item>
          <a
            href="/dashboard/tutorial#how-to-find-app-package-and-app-activity-of-your-android-app"
            target="_blank"
          >
            {t('howToGetAppActivity')}
          </a>
        </>
      )}
    </div>
  );
}

TestSuiteConfigSection.propTypes = {
  projectId: PropTypes.string,
  platformName: PropTypes.string,
  deviceType: PropTypes.string,
  fileId: PropTypes.string,
  bundleId: PropTypes.string,
  appPackage: PropTypes.string,
  appActivity: PropTypes.string,
  onSelectFile: PropTypes.func.isRequired,
  onSetFieldsValue: PropTypes.func.isRequired,
};
