import React from 'react';
import PropTypes from 'prop-types';
import { Layout } from 'antd';
import SharedHeader from './SharedHeader';
import './index.less';

const { Content } = Layout;

function SharedPage({ children, location }) {
  return (
    <Layout style={{ height: '100vh' }} id="container">
      <SharedHeader id="top" location={location} />
      <Content id="bottom" className="content-container">
        {children}
      </Content>
    </Layout>
  );
}

SharedPage.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  location: PropTypes.object.isRequired,
};

export default SharedPage;
