import {
  listSelectorFactory,
  loadingSelectorFactory,
  currentDataSelectorFactory,
} from 'redux/selectors';

const KEY = 'projects';

export const projectsSelector = listSelectorFactory(KEY);
export const projectLoadingSelector = loadingSelectorFactory(KEY);
export const currentProjectSelector = currentDataSelectorFactory(KEY);
