/* eslint-disable react/no-unescaped-entities */
/* eslint-disable max-len */
import React from 'react';
import { Button, Tag, Typography } from 'antd';
import useScrollToHash from 'hooks/useScrollToHash';
import { Trans } from 'gatsby-plugin-react-i18next';
import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import { SyncOutlined } from '@ant-design/icons';

const { Title, Paragraph, Text } = Typography;

function SetupMoppiumClient() {
  useScrollToHash();

  const data = useStaticQuery(graphql`
    query {
      diagnostic1: file(relativePath: { eq: "tutorial/diagnostic.png" }) {
        childImageSharp {
          fluid(maxWidth: 1024) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      diagnostic2: file(relativePath: { eq: "tutorial/diagnostic2.png" }) {
        childImageSharp {
          fluid(maxWidth: 1024) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      deviceManagement: file(
        relativePath: { eq: "tutorial/devices-management.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1024) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      moppiumClientStatus: file(
        relativePath: { eq: "tutorial/moppium-client-status.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1024) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <>
      <Title id="get-started">
        <Trans ns="tutorial" i18nKey="get-started" />
      </Title>
      <Title level={2} id="setup-moppium-client">
        <Trans ns="tutorial" i18nKey="setup-moppium-client" />
      </Title>
      <Title level={3} id="install-moppium-client">
        <Trans ns="tutorial" i18nKey="install-moppium-client" />
      </Title>
      <Paragraph>
        <Trans
          ns="tutorial"
          i18nKey="from-the-moppium-client-page-download-the-moppium-client-that-suits-your-environment"
        />
      </Paragraph>
      <Paragraph>
        <Trans
          ns="tutorial"
          i18nKey="open-the-downloaded-moppium-client-and-click-the-diagnostic-button"
        >
          <Button type="primary" size="small" />
        </Trans>
      </Paragraph>
      <Img
        fluid={data?.diagnostic1?.childImageSharp?.fluid}
        style={{ width: '50%' }}
        alt="diagnostic-1"
      />
      <Paragraph>
        <Trans
          ns="tutorial"
          i18nKey="on-the-diagnostic-screen-you-will-see-the-list-that-needs-to-install-on-your-pc"
        >
          <Text strong />
        </Trans>
        <Trans
          ns="tutorial"
          i18nKey="install-what-you-need-until-the-error-disappears"
        />
      </Paragraph>
      <Img
        fluid={data?.diagnostic2?.childImageSharp?.fluid}
        style={{ width: '50%' }}
        alt="diagnostic-2"
      />
      <Title level={3} id="test-devices-setting">
        <Trans ns="tutorial" i18nKey="test-devices-setting" />
      </Title>
      <Paragraph>
        <ul>
          <li>
            <Text code>
              <Trans ns="tutorial" i18nKey="ios" />
            </Text>
            <ul>
              <li>
                <Text code>
                  <Trans ns="tutorial" i18nKey="simulator" />
                </Text>
                {': '}
                <Trans
                  ns="tutorial"
                  i18nKey="from-the-moppium-client-screen-select-the-test-device-and-click-the-register-device-button"
                >
                  <Button size="small" type="primary" ghost />
                </Trans>
              </li>
              <li>
                <Text code>
                  <Trans ns="tutorial" i18nKey="real-device" />
                </Text>
                {': '}
                <Trans
                  ns="tutorial"
                  i18nKey="when-the-test-device-is-connected-to-the-pc-it-will-automatically-connect-to-the-moppium-client"
                />
              </li>
            </ul>
          </li>
          <li>
            <Text code>
              <Trans ns="tutorial" i18nKey="android" />
            </Text>
            <ul>
              <li>
                <Text code>
                  <Trans ns="tutorial" i18nKey="emulator" />
                </Text>
                {': '}
                <Trans
                  ns="tutorial"
                  i18nKey="when-the-emulator-is-started-it-will-automatically-connect-to-the-moppium-client"
                />
              </li>
              <li>
                <Text code>
                  <Trans ns="tutorial" i18nKey="real-device" />
                </Text>
                {': '}
                <Trans
                  ns="tutorial"
                  i18nKey="when-the-test-device-is-connected-to-the-pc-it-will-automatically-connect-to-the-moppium-client"
                />
              </li>
            </ul>
          </li>
        </ul>
      </Paragraph>
      <Paragraph>
        <Trans
          ns="tutorial"
          i18nKey="when-the-connection-is-completed-the-connected-device-will-be-displayed-on-device-management-screen-and-its-status-will-change-to-running"
        >
          <Text strong />
          <Tag color="green" style={{ marginRight: 'unset' }} />
        </Trans>
      </Paragraph>
      <Img
        fluid={data?.deviceManagement?.childImageSharp?.fluid}
        style={{ width: '50%' }}
        alt="deviceManagement"
      />
      <Paragraph>
        <Trans
          ns="tutorial"
          i18nKey="when-you-log-in-to-the-moppium-service-you-will-be-connected-to-the-moppium-client"
        >
          <Button
            size="small"
            type="primary"
            shape="round"
            icon={<SyncOutlined spin />}
          />
        </Trans>
      </Paragraph>
      <Img
        fluid={data?.moppiumClientStatus?.childImageSharp?.fluid}
        style={{ width: '70%' }}
        alt="moppiumClientStatus"
      />
    </>
  );
}

export default React.memo(SetupMoppiumClient);
