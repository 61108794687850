import React from 'react';
import PropTypes from 'prop-types';
import JustifyContentCenterWrapper from 'components/Styled/JustifyContentCenterWrapper';
import { Card, Typography } from 'antd';
import { Link } from 'gatsby-plugin-react-i18next';
import CardLabel from 'components/Styled/CardLabel';
import moment from 'moment';
import upperCase from 'lodash/upperCase';
import { useTranslation } from 'react-i18next';
import isEqual from 'react-fast-compare';

const { Paragraph } = Typography;

const SimpleCard = ({
  renderTitle,
  link,
  title,
  updatedAt,
  description,
  size,
  extra,
}) => {
  const { t } = useTranslation();

  return (
    <Card
      style={{ marginBottom: 20 }}
      title={<Link to={link}>{renderTitle ? renderTitle() : title}</Link>}
      className="test-suite-item"
      size={size}
      extra={extra}
    >
      <Paragraph>
        <CardLabel>{upperCase(t('createdAt'))}:</CardLabel>{' '}
        {moment(updatedAt).format('llll')}
      </Paragraph>
      <div>
        <CardLabel>{upperCase(t('description'))}:</CardLabel>
        <Paragraph ellipsis={{ rows: 2 }} style={{ minHeight: '1.6em' }}>
          {description}
        </Paragraph>
      </div>
      <JustifyContentCenterWrapper>
        <Link to={link}>{t('seeDetail')}</Link>
      </JustifyContentCenterWrapper>
    </Card>
  );
};

SimpleCard.propTypes = {
  renderTitle: PropTypes.func,
  link: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  updatedAt: PropTypes.string,
  size: PropTypes.string,
  extra: PropTypes.element,
};

SimpleCard.defaultProps = {
  size: 'default',
  renderTitle: null,
  extra: null,
};

export default React.memo(SimpleCard, isEqual);
