import { useEffect } from 'react';

export default function useScrollToHash() {
  useEffect(() => {
    const { hash } = window.location;
    if (hash) {
      const id = hash.replace('#', '');
      const element = document.getElementById(id);
      if (element) {
        setTimeout(() => {
          element.scrollIntoView({ block: 'start', behavior: 'smooth' });
        }, 200);
      }
    }
  }, []);
}
