import React, { useCallback, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Typography, Select, Form, Divider, Input, Button } from 'antd';
import { useTranslation } from 'react-i18next';
import upperFirst from 'lodash/upperFirst';
import AppleTeamCreationDrawer from 'containers/Settings/AppleTeamCreationDrawer';
import CustomInput from 'components/Styled/CustomInput';
import { IOS, DEVICE } from 'redux/ClientDevices/slice';

const { Option } = Select;
const { Text } = Typography;

export default function TestSuitePlatformEditionSection({
  platformName,
  target,
  deviceType,
  onChangeDeviceType,
  appleTeams,
  teamId,
  onSetFieldsValue,
}) {
  const { t } = useTranslation();
  const [visibleDrawer, setVisibleDrawer] = useState();

  useEffect(() => {
    onSetFieldsValue({ teamId });
  }, [onSetFieldsValue, teamId]);

  useEffect(() => {
    onSetFieldsValue({ deviceType });
  }, [deviceType, onSetFieldsValue]);

  const handleCancelDrawer = useCallback(() => {
    setVisibleDrawer(false);
  }, []);

  const handleAddAppleTeam = useCallback(() => {
    setVisibleDrawer(true);
  }, []);

  return (
    <>
      <Form.Item>
        <Text strong code>
          {t('platform')}:
        </Text>{' '}
        <Text>{platformName === 'ios' ? 'iOS' : upperFirst(platformName)}</Text>
        <Divider type="vertical" />
        <Text strong code>
          {t('target')}:
        </Text>{' '}
        <Text>{upperFirst(target)}</Text>
        <Divider type="vertical" />
        <Text strong code>
          {t('deviceType')}:
        </Text>{' '}
        {platformName === 'ios' && (
          <Form.Item
            noStyle
            name="deviceType"
            initialValue={deviceType}
            rules={[
              {
                required: true,
                message: t('selectDeviceType'),
              },
            ]}
          >
            <Select
              size="small"
              style={{ width: 120 }}
              onChange={onChangeDeviceType}
            >
              <Option value="device">{t('device')}</Option>
              <Option value="simulator">{t('simulator')}</Option>
            </Select>
          </Form.Item>
        )}
        {platformName === 'android' && deviceType === 'deviceAndEmulator' && (
          <>
            <Text>{t('deviceAndEmulator')}</Text>
            <Form.Item noStyle name="deviceType" initialValue={deviceType}>
              <Input style={{ display: 'none' }} />
            </Form.Item>
          </>
        )}
      </Form.Item>
      {platformName === IOS && deviceType === DEVICE && (
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <Form.Item style={{ flexGrow: 1 }}>
            <CustomInput label={t('teamName')}>
              <Form.Item
                name="teamId"
                rules={[
                  {
                    required: true,
                    message: t('requiredSelectTeamId'),
                  },
                ]}
                noStyle
              >
                <Select
                  placeholder={t('selectAppleTeam')}
                  className="remove-border-left"
                  allowClear
                >
                  {appleTeams?.map((team) => (
                    <Select.Option key={team.id} value={team.teamId}>
                      {`${team.teamName} (${team.teamId})`}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </CustomInput>
          </Form.Item>
          <Button style={{ marginLeft: 20 }} onClick={handleAddAppleTeam}>
            {t('addAppleTeam')}
          </Button>
        </div>
      )}
      <AppleTeamCreationDrawer
        visible={visibleDrawer}
        onCancel={handleCancelDrawer}
      />
    </>
  );
}

TestSuitePlatformEditionSection.propTypes = {
  platformName: PropTypes.string,
  target: PropTypes.string,
  deviceType: PropTypes.string,
  onChangeDeviceType: PropTypes.func.isRequired,
  appleTeams: PropTypes.array,
  teamId: PropTypes.string,
  onSetFieldsValue: PropTypes.func,
};
