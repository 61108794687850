import React, { useEffect, useState, useCallback } from 'react';
import {
  CaretRightOutlined,
  AppleFilled,
  AndroidFilled,
} from '@ant-design/icons';
import {
  Typography,
  Table,
  Button,
  Row,
  Col,
  Tag,
  Tooltip,
  Menu,
  Dropdown,
  Skeleton,
} from 'antd';
import PropTypes from 'prop-types';
import upperCase from 'lodash/upperCase';
import SEO from 'components/SEO';
import { Trans, useTranslation } from 'react-i18next';
import Label from 'components/Styled/Label';
import ActionBar from 'components/ActionBar';
import { currentJobSelector, jobLoadingSelector } from 'redux/Jobs/selector';
import { useSelector, useDispatch } from 'react-redux';
import BasicInformation from 'components/Display/BasicInformation';
import { useI18next } from 'gatsby-plugin-react-i18next';
import { getJobDetail, runJob, deleteJob } from 'redux/Jobs/slice';
import { currentFileSelector } from 'redux/Files/selector';
import { getFileDetail } from 'redux/Files/slice';
import { testCasesByTestSuiteIdSelector } from 'redux/TestCases/selectors';
import { getTestCases } from 'redux/TestCases/slice';
import {
  getDeviceResults,
  resetDeviceResults,
} from 'redux/DeviceResults/slice';
import cronstrue from 'cronstrue/i18n';
import './JobDetail.less';
import TestSuiteConfigDisplay from 'components/TestSuites/TestSuiteConfigDisplay';
import { getAppleTeams } from 'redux/AppleTeams/slice';
import { appleTeamByIdSelector } from 'redux/AppleTeams/selectors';
import {
  deviceResultLoadingSelector,
  groupedDeviceResultsSelector,
} from 'redux/DeviceResults/selector';
import moment from 'moment';
import { connectedClientIdsFilterSelector } from 'redux/ClientDevices/selectors';
import DeviceTypeCell from 'components/ClientDevices/DeviceTypeCell';
import JobEditionDrawer from './JobEditionDrawer';
import TestCaseResultTable from './TestCaseResultTable';
import { COLORS, RUNNING, TIMEOUT } from '../../utils/constants';

const { Title, Text, Paragraph } = Typography;

function Clients({ clientIds }) {
  return clientIds.map((clientId) => <Tag color="magenta">{clientId}</Tag>);
}

function Schedules({ schedules, language }) {
  return schedules.map((schedule) => (
    <Tag key={schedule} color="green">
      {cronstrue.toString(schedule, { locale: language })}
    </Tag>
  ));
}

function JobDetail({ jobId }) {
  const { t, i18n } = useTranslation();

  const { language } = i18n;
  const dispatch = useDispatch();
  const { navigate } = useI18next();

  const [jobEditionDrawerVisible, setJobEditionDrawerVisible] = useState(false);
  const [fakeRunning, setFakeRunning] = useState(false);
  useEffect(() => {
    if (jobId) {
      dispatch(getJobDetail({ id: jobId }));
      dispatch(getDeviceResults({ jobId, $refresh: true }));
    }
    return () => {
      dispatch(resetDeviceResults());
    };
  }, [dispatch, jobId]);

  const data = useSelector(currentJobSelector);
  const jobLoading = useSelector(jobLoadingSelector);
  const groupedDeviceResults = useSelector(groupedDeviceResultsSelector);
  const deviceResultLoading = useSelector(deviceResultLoadingSelector);
  const clientIdsFilter = useSelector(connectedClientIdsFilterSelector);
  const clientIds = clientIdsFilter();

  const { testSuiteId } = data;

  useEffect(() => {
    if (data.fileId) {
      dispatch(getFileDetail({ id: data.fileId }));
    }
  }, [data.fileId, dispatch]);

  const file = useSelector(currentFileSelector);

  useEffect(() => {
    if (data.testCaseIds) {
      dispatch(getTestCases({ id: testSuiteId, $refresh: true }));
    }
  }, [data.testCaseIds, dispatch, testSuiteId]);

  const testCasesByTestSuiteIdFilter = useSelector(
    testCasesByTestSuiteIdSelector
  );
  const testCaseList = testCasesByTestSuiteIdFilter(testSuiteId);
  const filteredTestCases = testCaseList.filter(
    ({ id }) => data?.testCaseIds?.indexOf(id) > -1
  );

  useEffect(() => {
    dispatch(getAppleTeams());
  }, [dispatch]);

  const appleTeamByIdFilter = useSelector(appleTeamByIdSelector);
  const { teamName } = appleTeamByIdFilter(data.teamId);

  const handleBack = () => {
    navigate(`/dashboard/test-suites/${testSuiteId}/jobs`);
  };

  const handleDelete = async () => {
    try {
      if (jobId) {
        await dispatch(deleteJob({ id: jobId }));
        handleBack();
      }
    } catch (error) {
      // TODO: Do nothing
    }
  };

  const handleCloseJobEditionDrawer = useCallback(() => {
    setJobEditionDrawerVisible(false);
  }, []);

  const testCaseColumns = [
    {
      title: t('name'),
      dataIndex: 'name',
      key: 'name',
    },
  ];

  const handleStartClient = ({ key }) => {
    if (key === 'all') {
      dispatch(runJob({ jobId }));
    } else {
      dispatch(runJob({ clientId: key, jobId }));
    }
    // TODO: Prevent user start too many job in the same time
    setFakeRunning(true);
    setTimeout(() => {
      setFakeRunning(false);
    }, 30000);
  };

  const menu = (
    <Menu onClick={handleStartClient}>
      <Menu.Item key="all" danger>
        {t('runsOnAllClientsBelow')}
      </Menu.Item>
      {clientIds.map((clientId) => (
        <Menu.Item key={clientId}>
          <Trans i18nKey="runOnSpecifiedClient">
            <Tag color="green">{{ clientId }}</Tag>
          </Trans>
        </Menu.Item>
      ))}
    </Menu>
  );

  const renderAddonBefore = () => (
    <Dropdown overlay={menu} placement="bottomLeft">
      <Button
        id="start-create-action"
        type="primary"
        size="large"
        icon={<CaretRightOutlined />}
        style={{ marginLeft: 10 }}
        loading={fakeRunning}
      >
        {t('startJob')}
      </Button>
    </Dropdown>
  );

  const handleEdit = useCallback(() => {
    setJobEditionDrawerVisible(true);
  }, []);

  const platformIcon =
    data.platformName === 'ios' ? (
      <AppleFilled style={{ fontSize: 20 }} />
    ) : (
      <AndroidFilled style={{ fontSize: 20 }} />
    );

  const deviceResultColumns = [
    {
      dataIndex: 'status',
      key: 'status',
      // eslint-disable-next-line react/display-name
      render: (_status, record) => {
        let status = _status;
        let tooltip;
        // TODO: Show timeout if device still show running after 2 hours
        if (status === RUNNING) {
          const startTime = moment(record.updatedAt);
          const now = moment();
          const duration = moment.duration(now.diff(startTime));
          const hours = duration.asHours();
          if (hours > 2) {
            status = TIMEOUT;
            tooltip = t('timeoutExplain');
          }
        }

        return (
          <DeviceTypeCell
            tooltip={tooltip}
            loading={status === RUNNING}
            label={upperCase(t(status))}
            color={COLORS[status]}
          />
        );
      },
    },
    {
      title: t('build'),
      dataIndex: 'build',
      key: 'build',
      // eslint-disable-next-line react/display-name
      render: (build) => <Tag color="gold">{build || 0}</Tag>,
    },
    {
      title: t('deviceName'),
      dataIndex: 'deviceName',
      key: 'deviceName',
      // eslint-disable-next-line react/display-name
      render: (deviceName, record) => (
        <>
          <Tag color="purple">{record.platformVersion}</Tag>{' '}
          <Text strong>{deviceName}</Text>
        </>
      ),
    },
    {
      title: t('testCases'),
      dataIndex: 'testCaseIds',
      key: 'testCaseIds',
      render: (testCaseIds) => testCaseIds?.length || 0,
    },
    {
      title: t('updatedAt'),
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      render: (updatedAt) => moment(updatedAt).format('llll'),
    },
  ];

  const expandedRowRender = (record) => (
    <TestCaseResultTable deviceResultId={record.id} />
  );

  return (
    <div className="layout">
      <SEO title={t('jobDetail')} description={t('SEODescription')} />
      <ActionBar
        title={t('jobDetail')}
        onBack={handleBack}
        renderAddonBefore={renderAddonBefore}
        onEdit={handleEdit}
        editTitle={t('editJob')}
        onDelete={handleDelete}
        deleteTitle={t('deleteJob')}
      />
      <Row gutter={32}>
        <Col span={8}>
          <Skeleton loading={jobLoading} paragraph={{ rows: 10 }}>
            <Title level={2}>
              {platformIcon} {data.name}
            </Title>
            <BasicInformation
              updatedAt={data.updatedAt}
              description={data.description}
            />
            <TestSuiteConfigDisplay {...data} file={file} teamName={teamName} />
            {data.emails && data.emails.length > 0 && (
              <>
                <Label>{upperCase(t('emails'))}: </Label>
                <Paragraph>
                  {data.emails.map((mail) => {
                    const isLongEmail = mail.length > 20;
                    const tagElem = (
                      <Tag size="small" key={mail}>
                        {isLongEmail ? `${mail.slice(0, 20)}...` : mail}
                      </Tag>
                    );
                    return isLongEmail ? (
                      <Tooltip title={mail} key={mail}>
                        {tagElem}
                      </Tooltip>
                    ) : (
                      tagElem
                    );
                  })}
                </Paragraph>
              </>
            )}
            <Label>{upperCase(t('testCases'))}</Label>
            <Table
              rowKey="id"
              style={{ marginTop: 20 }}
              columns={testCaseColumns}
              dataSource={filteredTestCases}
              pagination={{ pageSize: 5 }}
            />
          </Skeleton>
        </Col>
        <Col span={16}>
          <Skeleton loading={deviceResultLoading} paragraph={{ rows: 15 }}>
            <Label>{upperCase(t('schedules'))}: </Label>
            {(!data?.clientIds?.length || !data?.schedules?.length) && (
              <Paragraph>{t('noScheduleAndMoppiumClientSetUpYet')}</Paragraph>
            )}
            {!!data?.clientIds?.length && !!data?.schedules?.length && (
              <Paragraph>
                <Trans i18nKey="jobWillBeRunOnTheBelowClients">
                  <Clients clientIds={data.clientIds} />
                  <Schedules schedules={data.schedules} language={language} />
                </Trans>
              </Paragraph>
            )}
            <Label>{upperCase(t('executionResults'))}:</Label>
            {!groupedDeviceResults?.length && (
              <Paragraph>{t('noExecutionResultsYet')}</Paragraph>
            )}
            {!!groupedDeviceResults?.length &&
              groupedDeviceResults.map(([clientId, result]) => (
                <>
                  <Title level={4}>
                    {t('clientId')} {clientId}
                  </Title>
                  <Table
                    rowKey="id"
                    columns={deviceResultColumns}
                    dataSource={result}
                    expandable={{ expandedRowRender }}
                    loading={deviceResultLoading}
                  />
                </>
              ))}
          </Skeleton>
        </Col>
      </Row>
      <JobEditionDrawer
        id={jobId}
        visible={jobEditionDrawerVisible}
        testSuiteId={testSuiteId}
        onCancel={handleCloseJobEditionDrawer}
      />
    </div>
  );
}

JobDetail.propTypes = {
  jobId: PropTypes.string,
};

export default React.memo(JobDetail);
