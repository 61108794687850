import React, { useEffect, useState, useCallback } from 'react';
import { CaretUpOutlined } from '@ant-design/icons';
import { Button, Empty, Typography } from 'antd';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import { useTranslation } from 'react-i18next';
import SEO from 'components/SEO';
import ActionBar from 'components/ActionBar';
import { useDispatch, useSelector } from 'react-redux';
import { useI18next } from 'gatsby-plugin-react-i18next';
import {
  getTestCaseDetail,
  deleteTestCase,
  updateTestCase,
} from 'redux/TestCases/slice';
import {
  currentTestCaseSelector,
  currentTestCaseLoadingSelector,
} from 'redux/TestCases/selectors';
import TestCaseFormEditionDrawer from 'components/TestCases/TestCaseFormEditionDrawer';
import BasicInformation from 'components/Display/BasicInformation';
import SharedLayout from 'components/Styled/SharedLayout';

const { Title } = Typography;

export default function TestCaseDetail({ testCaseId }) {
  const dispatch = useDispatch();
  const [visible, setVisible] = useState(false);
  const { t } = useTranslation();
  const { navigate } = useI18next();

  const data = useSelector(currentTestCaseSelector);
  const loading = useSelector(currentTestCaseLoadingSelector);

  useEffect(() => {
    dispatch(getTestCaseDetail({ id: testCaseId }));
  }, [dispatch, testCaseId]);

  const handleBack = () => {
    const { testSuiteId } = data;

    navigate(`/dashboard/test-suites/${testSuiteId}`);
  };

  const handleDelete = async () => {
    if (!isEmpty(data)) {
      await dispatch(deleteTestCase({ id: testCaseId }));
      handleBack();
    } else {
      return null;
    }
  };

  const handleShowEdit = () => {
    if (!isEmpty(data)) {
      setVisible(true);
    } else {
      return null;
    }
  };

  const handleCancelEdit = useCallback(() => {
    setVisible(false);
  }, []);

  const handleEditTestCase = useCallback(
    async (payload) => {
      await dispatch(updateTestCase(payload));
    },
    [dispatch]
  );

  const pushInspector = () => {
    navigate(`/dashboard/inspector/${testCaseId}`);
  };

  const renderAddonBefore = () => (
    <Button
      id="start-create-action"
      type="primary"
      size="large"
      icon={<CaretUpOutlined />}
      style={{ marginLeft: 10 }}
      onClick={pushInspector}
    >
      {t('startInspector')}
    </Button>
  );

  return (
    <SharedLayout>
      <SEO title={t('testCaseDetail')} description={t('SEODescription')} />
      <ActionBar
        title={t('testCaseDetail')}
        onBack={handleBack}
        renderAddonBefore={renderAddonBefore}
        onDelete={handleDelete}
        deleteTitle={t('deleteTestCase')}
        onEdit={handleShowEdit}
        editTitle={t('editTestCase')}
      />
      <Title level={2}>{data.name}</Title>
      <BasicInformation
        updatedAt={data.updatedAt}
        description={data.description}
      />
      <TestCaseFormEditionDrawer
        visible={visible}
        data={data}
        onCancel={handleCancelEdit}
        onSubmit={handleEditTestCase}
      />
      {isEmpty(data) && !loading && <Empty />}
    </SharedLayout>
  );
}

TestCaseDetail.propTypes = {
  testCaseId: PropTypes.string,
};
