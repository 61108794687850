import { Alert, Drawer, Typography } from 'antd';
import React, { memo, useCallback } from 'react';
import PropTypes from 'prop-types';
import ReactFlow, { MiniMap, Controls, Background } from 'react-flow-renderer';
import { Trans, useTranslation } from 'gatsby-plugin-react-i18next';
import GoToNode from 'containers/ActionFlow/GoToNode';
import usePlatform from 'hooks/usePlatform';
import { useElements } from 'utils/action-flow';
import isEqual from 'react-fast-compare';
import { nodeTypes, edgeTypes } from './ActionFlowTypes';

const snapGrid = [16, 16];
const defaultPosition = [150, 0];

const { Text } = Typography;

function ActionFlowViewer({
  onClose,
  visible,
  pureElementsData,
  componentsData,
}) {
  const { t } = useTranslation();
  const elements = useElements(pureElementsData, componentsData, {
    editable: false,
  });
  const { isMac } = usePlatform();

  const handleNodeColor = useCallback((n) => {
    if (n.style?.background) return n.style.background;
    if (n.type === 'StartNode') return '#9cf196';
    if (n.type === 'EndNode') return '#ff0072';
    return '#e4e4e4';
  }, []);

  return (
    <Drawer
      title={t('executionResult')}
      onClose={onClose}
      visible={visible}
      placement="left"
      width={720}
      destroyOnClose
    >
      <ReactFlow
        className="action-flow"
        elements={elements}
        snapToGrid
        snapGrid={snapGrid}
        nodeTypes={nodeTypes}
        edgeTypes={edgeTypes}
        defaultPosition={defaultPosition}
      >
        <MiniMap nodeColor={handleNodeColor} borderRadius={2} />
        <Controls />
        <Alert
          className="manual"
          message={
            <Trans
              i18nKey="multiSelectItems"
              values={{ key: isMac ? 'Cmd' : 'Ctrl' }}
            >
              <Text strong />
              <Text keyboard />
            </Trans>
          }
          type="info"
        />
        <Background color="#aaa" gap={16} />
        <GoToNode elements={elements} />
      </ReactFlow>
    </Drawer>
  );
}

ActionFlowViewer.propTypes = {
  onClose: PropTypes.func,
  visible: PropTypes.bool,
  pureElementsData: PropTypes.object,
  componentsData: PropTypes.object,
};

export default memo(ActionFlowViewer, isEqual);
