import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { Button, Progress, Table, Tag } from 'antd';
import moment from 'moment';
import { RUNNING, FAILURE } from 'utils/constants';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { getTestCaseResults } from 'redux/TestCaseResults/slice';
import { testCaseResultsFilterSelector } from 'redux/TestCaseResults/selector';
import ActionFlowViewer from 'containers/ActionFlow/ActionFlowViewer';
import { cloneDeep } from 'lodash';
import TestCaseNameCell from './TestCaseNameCell';

const TestCaseResultTable = ({ deviceResultId }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [visible, setVisible] = useState();
  const [pureElementsData, setPureElementsData] = useState({});
  const [componentsData, setComponentsData] = useState({});

  const testCaseResultsFilter = useSelector(testCaseResultsFilterSelector);

  const tcResults = testCaseResultsFilter(deviceResultId);

  const handleShowDetail = (data) => {
    // TODO: Set error message into pure elements data
    if (data.exception) {
      const clonedElementsData = cloneDeep(data.pureElementsData);
      const index = data.executedSteps - 1 > 0 ? data.executedSteps - 1 : 0;
      const { key } = data.recordedActions[index];
      if (key && clonedElementsData[key]?.data) {
        clonedElementsData[key].data.errorMessage = data.exception;
      }
      setPureElementsData(clonedElementsData);
    } else {
      setPureElementsData(data.pureElementsData);
    }
    setComponentsData(data.componentsData);
    setVisible(true);
  };

  const handleCloseDrawer = useCallback(() => {
    setVisible(false);
  }, []);

  useEffect(() => {
    dispatch(getTestCaseResults({ deviceResultId }));
  }, [deviceResultId, dispatch]);

  const tcResultColumns = [
    {
      title: t('progress'),
      // eslint-disable-next-line react/display-name
      render: (_, { status, executedSteps, totalSteps }) => {
        let progressStatus;
        if (status === RUNNING) {
          progressStatus = 'active';
        } else if (status === FAILURE) {
          progressStatus = 'exception';
        }
        const percent =
          totalSteps === 0
            ? 100
            : Math.round((executedSteps / totalSteps) * 100);
        return (
          <div>
            <Progress status={progressStatus} percent={percent} />
            <Tag color="geekblue">
              {`${executedSteps} / ${totalSteps} ${t('steps')}`}
            </Tag>
          </div>
        );
      },
    },
    {
      title: t('testCase'),
      dataIndex: 'testCaseId',
      key: 'testCaseId',
      // eslint-disable-next-line react/display-name
      render: (id) => <TestCaseNameCell testCaseId={id} />,
    },
    // TODO: Temporarily Hide
    // {
    //   title: t('duration'),
    //   dataIndex: 'duration',
    //   key: 'duration',
    //   render: (seconds) => moment.utc(seconds * 1000).format('HH:mm:ss'),
    // },
    {
      title: t('updatedAt'),
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      render: (updatedAt) => moment(updatedAt).format('llll'),
    },
    {
      width: 10,
      // eslint-disable-next-line react/display-name
      render: (_, record) => (
        <Button type="link" onClick={() => handleShowDetail(record)}>
          {t('detail')}
        </Button>
      ),
    },
  ];

  return (
    <div>
      <Table
        rowKey="id"
        columns={tcResultColumns}
        dataSource={tcResults}
        pagination={false}
      />
      <ActionFlowViewer
        visible={visible}
        onClose={handleCloseDrawer}
        pureElementsData={pureElementsData}
        componentsData={componentsData}
      />
    </div>
  );
};

TestCaseResultTable.propTypes = {
  deviceResultId: PropTypes.string,
};

export default TestCaseResultTable;
