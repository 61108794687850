import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  FileSyncOutlined,
  AndroidFilled,
  AppleFilled,
} from '@ant-design/icons';
import { Skeleton, Typography, Button, Row, Col } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import {
  currentTestSuiteSelector,
  currentTestSuiteLoadingSelector,
} from 'redux/TestSuites/selectors';
import { useI18next } from 'gatsby-plugin-react-i18next';
import { getTestCases, createTestCase } from 'redux/TestCases/slice';
import {
  // removeCurrentTestSuite,
  getTestSuiteDetail,
  deleteTestSuite,
} from 'redux/TestSuites/slice';
import { getFileDetail } from 'redux/Files/slice';
import {
  testCasesByTestSuiteIdSelector,
  testCasesLoadingByTestSuiteIdSelector,
} from 'redux/TestCases/selectors';
import TestCaseList from 'components/TestCases/TestCaseList';
import SEO from 'components/SEO';
import { useTranslation } from 'react-i18next';
import isEmpty from 'lodash/isEmpty';
import upperCase from 'lodash/upperCase';
import Label from 'components/Styled/Label';
import TestSuiteEditionDrawer from 'containers/TestSuites/TestSuiteEditionDrawer';
import TestCaseFormCreationDrawer from 'components/TestCases/TestCaseFormCreationDrawer';
import TestSuiteConfigDisplay from 'components/TestSuites/TestSuiteConfigDisplay';
import { currentFileSelector } from 'redux/Files/selector';
import BasicInformation from 'components/Display/BasicInformation';
import ClientDevices from 'components/ClientDevices';
import {
  clientDevicesByTypeFilterSelector,
  connectedClientIdsFilterSelector,
  localClientIdSelector,
} from 'redux/ClientDevices/selectors';
import ActionBar from 'components/ActionBar';
import { getAppleTeams } from 'redux/AppleTeams/slice';
import { appleTeamByIdSelector } from 'redux/AppleTeams/selectors';

const { Title } = Typography;

export default function TestSuiteDetailPage({ testSuiteId }) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { navigate } = useI18next();

  const onGetFileDetail = useCallback(
    (payload) => dispatch(getFileDetail(payload)),
    [dispatch]
  );
  const data = useSelector(currentTestSuiteSelector);

  const {
    projectId,
    name,
    description,
    updatedAt,
    platformName,
    deviceType,
    target,
    bundleId,
    appPackage,
    appActivity,
    fileId,
    teamId,
  } = data;

  const handleBack = useCallback(() => {
    navigate(`/dashboard/projects/${projectId}`);
  }, [navigate, projectId]);

  const loading = useSelector(currentTestSuiteLoadingSelector);
  const testCasesByTestSuiteIdFilter = useSelector(
    testCasesByTestSuiteIdSelector
  );
  const items = testCasesByTestSuiteIdFilter(testSuiteId);
  const testCasesLoadingByTestSuiteIdFilter = useSelector(
    testCasesLoadingByTestSuiteIdSelector
  );
  const itemsLoading = testCasesLoadingByTestSuiteIdFilter(testSuiteId);
  const file = useSelector(currentFileSelector);
  const localClientId = useSelector(localClientIdSelector);
  const clientIdsFilter = useSelector(connectedClientIdsFilterSelector);
  const clientIds = clientIdsFilter(true);
  const clientDevicesByTypeFilter = useSelector(
    clientDevicesByTypeFilterSelector
  );
  const clientDevices = clientDevicesByTypeFilter(
    localClientId,
    platformName,
    deviceType
  );

  const [testCaseVisible, setTestCaseVisible] = useState(false);
  const [testSuiteVisible, setTestSuiteVisible] = useState(false);

  useEffect(() => {
    dispatch(getTestSuiteDetail({ id: testSuiteId }));
  }, [dispatch, testSuiteId]);

  useEffect(() => {
    dispatch(getTestCases({ id: testSuiteId }));
  }, [dispatch, testSuiteId]);

  useEffect(() => {
    if (fileId) {
      dispatch(getFileDetail({ id: fileId }));
    }
  }, [dispatch, fileId, onGetFileDetail]);

  useEffect(() => {
    dispatch(getAppleTeams());
  }, [dispatch]);

  const appleTeamByIdFilter = useSelector(appleTeamByIdSelector);
  const { teamName } = appleTeamByIdFilter(teamId);

  const handleCreateTestCase = useCallback(
    async (payload) => {
      await dispatch(createTestCase({ id: testSuiteId, ...payload }));
    },
    [dispatch, testSuiteId]
  );

  const handleCancelTestSuite = useCallback(() => {
    setTestSuiteVisible(false);
  }, []);

  const handleCancelTestCase = useCallback(() => {
    setTestCaseVisible(false);
  }, []);

  const handleEdit = () => {
    if (!isEmpty(data)) {
      setTestSuiteVisible(true);
    } else {
      return null;
    }
  };
  const handleCreate = useCallback(() => {
    if (!isEmpty(data)) {
      setTestCaseVisible(true);
    } else {
      return null;
    }
  }, [data]);

  const handleDelete = async () => {
    if (!isEmpty(data)) {
      await dispatch(deleteTestSuite({ id: testSuiteId }));
      handleBack();
    } else {
      return null;
    }
  };

  const handleShowJobList = useCallback(() => {
    navigate(`/dashboard/test-suites/${testSuiteId}/jobs`);
  }, [navigate, testSuiteId]);

  const renderExpandActions = () => (
    <Button
      type="primary"
      size="large"
      icon={<FileSyncOutlined />}
      style={{ marginLeft: 10 }}
      onClick={handleShowJobList}
    >
      {t('jobManagement')}
    </Button>
  );

  const icon =
    platformName === 'ios' ? (
      <AppleFilled style={{ fontSize: 30 }} />
    ) : (
      <AndroidFilled style={{ fontSize: 30 }} />
    );

  return (
    <div className="layout">
      <SEO title={t('testSuiteDetail')} description={t('SEODescription')} />
      <ActionBar
        renderExpandActions={renderExpandActions}
        title={t('testSuiteDetail')}
        onBack={handleBack}
        onCreate={handleCreate}
        createTitle={t('createTestCase')}
        onDelete={handleDelete}
        deleteTitle={t('deleteTestSuite')}
        onEdit={handleEdit}
        editTitle={t('editTestSuite')}
      />
      <Skeleton loading={loading} paragraph={{ rows: 10 }}>
        <Title level={2}>
          {icon} {name}
        </Title>
        <Row gutter={24}>
          <Col className="gutter-row" span={12}>
            <Row gutter={16}>
              <Col span={8}>
                <BasicInformation
                  updatedAt={updatedAt}
                  description={description}
                />
              </Col>
              <Col span={16}>
                <TestSuiteConfigDisplay
                  {...{
                    deviceType,
                    target,
                    platformName,
                    bundleId,
                    fileId,
                    file,
                    appPackage,
                    appActivity,
                    teamId,
                    teamName,
                  }}
                />
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={24}>
                <Label>{upperCase(t('client'))}:</Label>
                <ClientDevices
                  clientDevices={clientDevices}
                  localClientId={localClientId}
                  clientIds={clientIds}
                />
              </Col>
            </Row>
          </Col>
          <Col className="gutter-row" span={12}>
            <Label>{upperCase(t('testCaseList'))}: </Label>
            <div style={{ marginBottom: 10 }} />
            <TestCaseList
              loading={itemsLoading}
              data={items}
              onShowTestCaseCreationForm={handleCreate}
            />
          </Col>
        </Row>
      </Skeleton>
      <TestCaseFormCreationDrawer
        visible={testCaseVisible}
        onCancel={handleCancelTestCase}
        onSubmit={handleCreateTestCase}
      />
      <TestSuiteEditionDrawer
        projectId={projectId}
        visible={testSuiteVisible}
        data={data}
        onCancel={handleCancelTestSuite}
      />
    </div>
  );
}

TestSuiteDetailPage.propTypes = {
  testSuiteId: PropTypes.string,
};
