/* eslint-disable react/no-unescaped-entities */
/* eslint-disable max-len */
import React from 'react';
import { Anchor } from 'antd';
import { useTranslation } from 'react-i18next';

const { Link } = Anchor;

function DocMenu() {
  const { t } = useTranslation(['tutorial']);

  return (
    <Anchor
      style={{ width: 300, offsetTop: 64, background: 'unset' }}
      getContainer={() => document.querySelector('#tutorial #right')}
      showInkInFixed
      affix
    >
      <Link href="#introduction" title={t('introduction')} />
      <Link href="#get-started" title={t('get-started')}>
        <Link href="#setup-moppium-client" title={t('setup-moppium-client')}>
          <Link
            href="#install-moppium-client"
            title={t('install-moppium-client')}
          />
          <Link
            href="#test-devices-setting"
            title={t('test-devices-setting')}
          />
        </Link>
        <Link href="#create-test-cases" title={t('create-test-cases')}>
          <Link href="#create-project" title={t('create-project')} />
          <Link href="#create-test-suite" title={t('create-test-suite')}>
            <Link
              href="#how-to-find-app-package-and-app-activity-of-your-android-app"
              title={t(
                'how-to-find-app-package-and-app-activity-of-your-android-app'
              )}
            />
          </Link>
          <Link href="#create-test-case" title={t('create-test-case')} />
        </Link>
        <Link
          href="#create-and-execute-job"
          title={t('create-and-execute-job')}
        >
          <Link href="#create-job" title={t('create-job')} />
          <Link href="#execute-job" title={t('execute-job')} />
          <Link
            href="#check-job-execution-results"
            title={t('check-job-execution-results')}
          />
        </Link>
        <Link href="#how-to-use-inspector" title={t('how-to-use-inspector')} />
      </Link>
    </Anchor>
  );
}

export default React.memo(DocMenu);
