import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { AndroidFilled, AppleFilled, DeleteOutlined } from '@ant-design/icons';
import { Button, Card, Descriptions, Popconfirm, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import './FileCard.less';
import isEqual from 'react-fast-compare';

const { Text, Title } = Typography;
const { Item } = Descriptions;

function FileCard({ data, selectedId, onDelete }) {
  const { t } = useTranslation();
  const [selectedTab, setSelectedTab] = useState('info');

  const {
    id,
    name,
    appPlatform,
    appName,
    updatedAt,
    version,
    minOSVersion,
    bundleId,
    appPackage,
    appActivity,
  } = data;

  const isAndroid = appPlatform === 'android';
  const isIOS = appPlatform === 'ios';

  const icon = isIOS ? (
    <AppleFilled style={{ zIndex: 2 }} />
  ) : (
    <AndroidFilled style={{ zIndex: 2 }} />
  );

  const handleDelete = () => {
    onDelete(id);
  };

  const tabList = [
    {
      key: 'info',
      tab: t('info'),
    },
    {
      key: 'detail',
      tab: t('detail'),
    },
  ];

  const contents = {
    info: (
      <Descriptions column={1} size="small">
        <Item>
          <Title level={5}>
            {icon} {name}
          </Title>
        </Item>
        <Item label={<Text strong>{t('appName')}</Text>}>{appName}</Item>
        <Item contentStyle={{ justifyContent: 'flex-end' }}>
          <Text style={{ fontStyle: 'italic' }}>
            {moment(updatedAt).fromNow()}
          </Text>
        </Item>
      </Descriptions>
    ),
    detail: (
      <Descriptions column={1} size="small">
        <Item label={<Text strong>{t('appName')}</Text>}>{appName}</Item>
        {isIOS && (
          <Item label={<Text strong>{t('bundleId')}</Text>}>{bundleId}</Item>
        )}
        {isAndroid && (
          <Item label={<Text strong>{t('appPackage')}</Text>}>
            {appPackage}
          </Item>
        )}
        {isAndroid && (
          <Item label={<Text strong>{t('appActivity')}</Text>}>
            {appActivity}
          </Item>
        )}
        <Item label={<Text strong>{t('version')}</Text>}>{version}</Item>
        <Item label={<Text strong>{t('minOSVersion')}</Text>}>
          {minOSVersion}
        </Item>
        <Item label={<Text strong>{t('updatedAt')}</Text>}>
          {moment(updatedAt).format('llll')}
        </Item>
      </Descriptions>
    ),
  };

  const extra = (
    <Popconfirm title={t('confirmDelete')} onConfirm={handleDelete}>
      <Button icon={<DeleteOutlined />} danger shape="circle" type="primary" />
    </Popconfirm>
  );

  return (
    <Card
      className={id === selectedId ? 'selected-card' : null}
      data-id={id}
      hoverable
      tabBarExtraContent={extra}
      tabList={tabList}
      onTabChange={setSelectedTab}
      size="small"
    >
      {contents[selectedTab]}
    </Card>
  );
}

FileCard.propTypes = {
  data: PropTypes.object,
  selectedId: PropTypes.string,
  onDelete: PropTypes.func,
};

export default React.memo(FileCard, isEqual);
