import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { getTestSuites } from 'redux/TestSuites/slice';
import {
  testSuiteLoadingByProjectIdSelector,
  testSuitesByProjectIdSelector,
} from 'redux/TestSuites/selectors';
import { useTranslation } from 'react-i18next';
import ItemWrapper from 'components/Projects/ItemWrapper';
import Label from 'components/Styled/Label';
import { Link } from 'gatsby-plugin-react-i18next';
import { Typography, Spin, Row, Col } from 'antd';
import upperCase from 'lodash/upperCase';
import JustifyContentCenterWrapper from 'components/Styled/JustifyContentCenterWrapper';
import TestSuiteItem from 'components/TestSuites/TestSuiteItem';
import BasicInformation from 'components/Display/BasicInformation';
import isEqual from 'react-fast-compare';

const { Title } = Typography;

function ProjectCard({ data }) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { id, name, description, updatedAt } = data;

  const testSuiteLoadingByProjectIdFilter = useSelector(
    testSuiteLoadingByProjectIdSelector
  );
  const testSuiteLoading = testSuiteLoadingByProjectIdFilter(id);
  const testSuitesByProjectIdFilter = useSelector(
    testSuitesByProjectIdSelector
  );
  const testSuites = testSuitesByProjectIdFilter(id);

  useEffect(() => {
    dispatch(getTestSuites({ id }));
  }, [dispatch, id]);

  if (!data) {
    return null;
  }

  return (
    <ItemWrapper>
      <Label>{upperCase(t('projectName'))}: </Label>
      <Link to={`/dashboard/projects/${id}`}>
        <Title level={3}>{name}</Title>
      </Link>
      <BasicInformation updatedAt={updatedAt} description={description} />
      <Spin spinning={testSuiteLoading}>
        <Row gutter={20}>
          {testSuites.slice(0, 3).map((item) => (
            <Col span={8} key={item.id}>
              <TestSuiteItem data={item} size="small" />
            </Col>
          ))}
        </Row>
      </Spin>
      <JustifyContentCenterWrapper style={{ marginTop: 20 }}>
        <Link to={`/dashboard/projects/${id}`}>{t('seeMore')}</Link>
      </JustifyContentCenterWrapper>
    </ItemWrapper>
  );
}

ProjectCard.propTypes = {
  data: PropTypes.object.isRequired,
};

export default React.memo(ProjectCard, isEqual);
