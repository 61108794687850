/* eslint-disable react/no-unescaped-entities */
/* eslint-disable max-len */
import React from 'react';
import { Typography, Button } from 'antd';
import useScrollToHash from 'hooks/useScrollToHash';
import { Trans } from 'gatsby-plugin-react-i18next';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import Icon, {
  ArrowLeftOutlined,
  CaretRightOutlined,
  CloseOutlined,
  EyeOutlined,
  PauseOutlined,
  ReloadOutlined,
  SaveOutlined,
  SelectOutlined,
  SwapOutlined,
} from '@ant-design/icons';
import SvgIcons from 'images/svg';

const { Title, Paragraph, Text } = Typography;

function HowToUseInspector() {
  useScrollToHash();

  const data = useStaticQuery(graphql`
    query {
      sourceTree: file(relativePath: { eq: "tutorial/source-tree.png" }) {
        childImageSharp {
          fluid(maxWidth: 1024) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      sourceCode: file(relativePath: { eq: "tutorial/source-code.png" }) {
        childImageSharp {
          fluid(maxWidth: 1024) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      actionsFlow: file(relativePath: { eq: "tutorial/actions-flow.png" }) {
        childImageSharp {
          fluid(maxWidth: 1024) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      screenshot: file(relativePath: { eq: "tutorial/screenshot.png" }) {
        childImageSharp {
          fluid(maxWidth: 1024) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      inspector: file(relativePath: { eq: "tutorial/inspector-3.png" }) {
        childImageSharp {
          fluid(maxWidth: 1024) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <>
      <Title level={2} id="how-to-use-inspector">
        <Trans ns="tutorial" i18nKey="how-to-use-inspector" />
      </Title>
      <Paragraph>
        <Trans
          ns="tutorial"
          i18nKey="lets-describe-about-the-features-of-inspector-the-inspector-has-a-recording-playback-function-for-creating-test-cases"
        />
        <Trans
          ns="tutorial"
          i18nKey="by-recording-the-actions-you-have-done-on-the-screenshot-you-can-smartly-create-test-cases"
        />
        <Img
          fluid={data?.inspector?.childImageSharp?.fluid}
          style={{ width: '70%' }}
          alt="inspector-3"
        />
      </Paragraph>
      <Paragraph>
        <ol>
          <li>
            <Text strong>
              <Trans ns="tutorial" i18nKey="screenshot-and-selected-element" />
              {': '}
            </Text>
            <Trans
              ns="tutorial"
              i18nKey="you-will-see-a-screenshot-of-the-testing-device-screen-in-the-screenshot-you-can-perform-action-of-touchinput-clear-and-assert-with-selected-element"
            >
              <Button type="primary" ghost size="small" />
            </Trans>
            <Img
              fluid={data?.screenshot?.childImageSharp?.fluid}
              style={{ width: '70%' }}
              alt="screenshot"
            />
          </li>
          <li>
            <Text strong>
              <Trans ns="tutorial" i18nKey="action-flow" />
              {': '}
            </Text>
            <Trans
              ns="tutorial"
              i18nKey="the-recorded-actions-are-displayed-here"
            />
            <Trans
              ns="tutorial"
              i18nKey="all-of-these-actions-can-be-able-to-supervise"
            />
            <Trans
              ns="tutorial"
              i18nKey="it-can-also-re-perform-by-clicking-the-play-button"
            >
              <Button
                type="primary"
                size="small"
                icon={<CaretRightOutlined />}
                shape="circle"
              />
            </Trans>
            <Img
              fluid={data?.actionsFlow?.childImageSharp?.fluid}
              style={{ width: '70%' }}
              alt="actions-flow"
            />
          </li>
          <li>
            <Text strong>
              <Trans ns="tutorial" i18nKey="source-code" />
              {': '}
            </Text>
            <Trans
              ns="tutorial"
              i18nKey="you-can-download-the-source-code-corresponding-to-the-framework-javanodejs"
            />
            <Img
              fluid={data?.sourceCode?.childImageSharp?.fluid}
              style={{ width: '70%' }}
              alt="source-code"
            />
          </li>
          <li>
            <Text strong>
              <Trans ns="tutorial" i18nKey="element-tree" />
              {': '}
            </Text>
            <Trans
              ns="tutorial"
              i18nKey="the-dom-of-the-current-screen-is-displayed-here"
            />
            <Trans
              ns="tutorial"
              i18nKey="you-can-also-work-with-these-elements-by-selecting-them"
            />
            <Trans
              ns="tutorial"
              i18nKey="if-the-screenshot-element-selection-doesnt-work-you-can-also-use-this-to-find-the-right-element"
            />
            <Img
              fluid={data?.sourceTree?.childImageSharp?.fluid}
              style={{ width: '70%' }}
              alt="source-tree"
            />
          </li>
          <li>
            <Paragraph strong>
              <Trans ns="tutorial" i18nKey="screenshot-action-left" />
              {': '}
            </Paragraph>
            <ul>
              <li>
                <Paragraph>
                  <Button
                    type="primary"
                    size="small"
                    icon={<ArrowLeftOutlined />}
                  />
                  <Text code>
                    <Trans ns="tutorial" i18nKey="back-to-previous-screen" />
                  </Text>
                  {': '}
                  <Trans
                    ns="tutorial"
                    i18nKey="use-this-action-if-you-want-to-back-to-the-previous-screen"
                  />
                </Paragraph>
              </li>
              <li>
                <Paragraph>
                  <Button
                    type="primary"
                    size="small"
                    icon={<SelectOutlined />}
                  />
                  <Text code>
                    <Trans ns="tutorial" i18nKey="select-element" />
                  </Text>
                  {': '}
                  <Trans
                    ns="tutorial"
                    i18nKey="touch-input-clear-and-assert-operations-can-be-performed-by-selecting-the-element"
                  />
                </Paragraph>
              </li>
              <li>
                <Paragraph>
                  <Button type="primary" size="small" icon={<SwapOutlined />} />
                  <Text code>
                    <Trans ns="tutorial" i18nKey="swipe-in-coordinates" />
                  </Text>
                  {': '}
                  <Trans
                    ns="tutorial"
                    i18nKey="you-can-swipe-by-specifying-the-coordinates"
                  />
                </Paragraph>
              </li>
              <li>
                <Paragraph>
                  <Button
                    type="primary"
                    size="small"
                    icon={<Icon component={SvgIcons.Touch} />}
                  />
                  <Text code>
                    <Trans ns="tutorial" i18nKey="tap-by-coordinates" />
                  </Text>
                  {': '}
                  <Trans
                    ns="tutorial"
                    i18nKey="you-can-tap-by-specifying-coordinates"
                  />
                </Paragraph>
              </li>
              <li>
                <Paragraph>
                  <Button
                    type="primary"
                    size="small"
                    icon={<Icon component={SvgIcons.Sleep} />}
                  />
                  <Text code>
                    <Trans ns="tutorial" i18nKey="wait-time-setting" />
                  </Text>
                  {': '}
                  <Trans
                    ns="tutorial"
                    i18nKey="you-can-enter-the-waiting-time-seconds-by-using-action"
                  />
                  <Trans
                    ns="tutorial"
                    i18nKey="however-the-default-waiting-time-is-3-seconds-so-if-it-is-still-insufficient-you-can-adjust-it"
                  />
                </Paragraph>
              </li>
              <li>
                <Paragraph>
                  <Button type="primary" size="small" icon={<EyeOutlined />} />
                  <Text code>
                    <Trans
                      ns="tutorial"
                      i18nKey="search-elements-until-visible"
                    />
                  </Text>
                  {': '}
                  <Trans
                    ns="tutorial"
                    i18nKey="by-using-this-action-you-can-scroll-the-screen-until-the-element-is-visible"
                  />
                </Paragraph>
              </li>
            </ul>
          </li>
          <li>
            <Paragraph strong>
              <Trans ns="tutorial" i18nKey="inspection-action-right" />
              {': '}
            </Paragraph>
            <ul>
              <li>
                <Paragraph>
                  <Button
                    type="primary"
                    size="small"
                    icon={<PauseOutlined />}
                  />
                  <Text code>
                    <Trans ns="tutorial" i18nKey="record-button" />
                  </Text>
                  {': '}
                  <Trans
                    ns="tutorial"
                    i18nKey="the-record-button-is-enabled-by-default"
                  />
                  <Trans
                    ns="tutorial"
                    i18nKey="if-you-want-to-work-with-screenshots-without-recording-actions-you-can-disable-recording-for-a-while"
                  />
                </Paragraph>
              </li>
              <li>
                <Paragraph>
                  <Button
                    type="primary"
                    size="small"
                    icon={<ReloadOutlined />}
                  />
                  <Text code>
                    <Trans
                      ns="tutorial"
                      i18nKey="refresh-source-and-screenshot"
                    />
                  </Text>
                  {': '}
                  <Trans
                    ns="tutorial"
                    i18nKey="refresh-and-capture-source-and-screenshot"
                  />
                </Paragraph>
              </li>
              <li>
                <Paragraph>
                  <Button type="primary" size="small" icon={<SaveOutlined />} />
                  <Text code>
                    <Trans ns="tutorial" i18nKey="save-action" />
                  </Text>
                  {': '}
                  <Trans
                    ns="tutorial"
                    i18nKey="you-can-save-the-recorded-test-case"
                  />
                </Paragraph>
              </li>
              <li>
                <Paragraph>
                  <Button
                    type="primary"
                    size="small"
                    icon={<CloseOutlined />}
                  />
                  <Text code>
                    <Trans ns="tutorial" i18nKey="close-inspector-screen" />
                  </Text>
                  {': '}
                  <Trans
                    ns="tutorial"
                    i18nKey="use-this-action-to-exit-the-inspector"
                  />
                </Paragraph>
              </li>
            </ul>
          </li>
        </ol>
      </Paragraph>
    </>
  );
}

export default React.memo(HowToUseInspector);
