import React, { useCallback } from 'react';
import { AppleFilled, AndroidFilled } from '@ant-design/icons';
import { Typography } from 'antd';
import PropTypes from 'prop-types';
import SimpleCard from 'components/Cards/SimpleCard';

const { Text } = Typography;

function TestSuiteItem({ data, size }) {
  const { id, name, description, platformName, updatedAt } = data;

  // eslint-disable-next-line react/display-name
  const renderTitle = useCallback(() => {
    const icon =
      platformName === 'ios' ? (
        <AppleFilled style={{ fontSize: 20 }} />
      ) : (
        <AndroidFilled style={{ fontSize: 20 }} />
      );
    return (
      <Text>
        {icon} {name}
      </Text>
    );
  }, [name, platformName]);

  return (
    <SimpleCard
      size={size}
      renderTitle={renderTitle}
      link={`/dashboard/test-suites/${id}`}
      updatedAt={updatedAt}
      description={description}
    />
  );
}

TestSuiteItem.propTypes = {
  data: PropTypes.object.isRequired,
  size: PropTypes.string,
};

TestSuiteItem.defaultProps = {
  size: 'normal',
};

export default TestSuiteItem;
