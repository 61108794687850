import React from 'react';
import { Button, Col, Tooltip } from 'antd';
import PropTypes from 'prop-types';
import SimpleCard from 'components/Cards/SimpleCard';
import { CaretUpOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { useI18next } from 'gatsby-plugin-react-i18next';

const ExtraView = ({ testCaseId }) => {
  const { t } = useTranslation();
  const { navigate } = useI18next();

  const handleOpenInspector = () => {
    navigate(`/dashboard/inspector/${testCaseId}`);
  };

  return (
    <Tooltip title={t('startInspector')}>
      <Button
        type="primary"
        shape="circle"
        icon={<CaretUpOutlined />}
        onClick={handleOpenInspector}
      />
    </Tooltip>
  );
};

ExtraView.propTypes = {
  testCaseId: PropTypes.string,
};

export default function TestCaseItem({ span, item }) {
  const { id, name, description, updatedAt } = item;

  return (
    <Col span={span}>
      <SimpleCard
        title={name}
        link={`/dashboard/test-cases/${id}`}
        updatedAt={updatedAt}
        description={description}
        extra={<ExtraView testCaseId={id} />}
      />
    </Col>
  );
}

TestCaseItem.propTypes = {
  span: PropTypes.number,
  item: PropTypes.object,
};

TestCaseItem.defaultProps = {
  span: 8,
};
