import { useContext } from 'react';
import { I18nextContext } from 'gatsby-plugin-react-i18next';

/**
 * Prerequisites: Create page for local in gatsby-node.js
 * @param {*} basePath
 */
export default function useLocalizedPath(basePath = '') {
  const { language, defaultLanguage } = useContext(I18nextContext);

  const path = `${
    language === defaultLanguage ? '' : `/${language}`
  }/${basePath}`;
  return path;
}
