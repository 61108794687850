import React from 'react';
import PropTypes from 'prop-types';
import SEO from 'components/SEO';
import { useTranslation } from 'react-i18next';

import ActionBar from 'components/ActionBar';
import { useI18next } from 'gatsby-plugin-react-i18next';
import SharedLayout from 'components/Styled/SharedLayout';
import AppStorage from './AppStorage';

export default function AppStoragePage({ projectId }) {
  const { t } = useTranslation();
  const { navigate } = useI18next();

  const handleBack = () => {
    navigate(`/dashboard/projects/${projectId}`);
  };

  return (
    <SharedLayout>
      <SEO title={`${t('appStorage')}`} description={t('SEODescription')} />
      <ActionBar title={t('appStorage')} onBack={handleBack} />
      <AppStorage projectId={projectId} />
    </SharedLayout>
  );
}

AppStoragePage.propTypes = {
  projectId: PropTypes.string,
};
