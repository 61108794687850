import React, { useState, useEffect, useCallback } from 'react';
import { List, Empty, Button } from 'antd';
import PropTypes from 'prop-types';
import SEO from 'components/SEO';
import { useTranslation } from 'react-i18next';
import ActionBar from 'components/ActionBar';
import { useI18next } from 'gatsby-plugin-react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { jobsSelector, jobLoadingSelector } from 'redux/Jobs/selector';
import SimpleCard from 'components/Cards/SimpleCard';
import { getJobs } from 'redux/Jobs/slice';
import JobCreationDrawer from './JobCreationDrawer';

function JobList({ testSuiteId }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [drawerVisible, setDrawerVisible] = useState(false);
  const { navigate } = useI18next();

  useEffect(() => {
    if (testSuiteId) {
      dispatch(getJobs({ id: testSuiteId, $refresh: true }));
    }
  }, [dispatch, testSuiteId]);

  // const clientJobs = getDataArrFilter({ data, ids })({ service: 'client' });
  const data = useSelector(jobsSelector);
  const loading = useSelector(jobLoadingSelector);

  const handleCreate = useCallback(() => {
    setDrawerVisible(true);
  }, []);

  const handleBack = useCallback(() => {
    navigate(`/dashboard/test-suites/${testSuiteId}/`);
  }, [navigate, testSuiteId]);

  const handleCloseDrawer = useCallback(() => {
    setDrawerVisible(false);
  }, []);

  const empty = (
    <Empty description={<span>{t('noJob')}</span>}>
      <Button type="primary" onClick={handleCreate}>
        {t('createNow')}
      </Button>
    </Empty>
  );

  return (
    <div className="layout">
      <SEO title={t('jobManagement')} description={t('SEODescription')} />
      <ActionBar
        title={t('jobManagement')}
        onBack={handleBack}
        createTitle={t('createJob')}
        onCreate={handleCreate}
      />
      <List
        pagination={{ pageSize: 10 }}
        grid={{ gutter: 16, column: 2 }}
        dataSource={data}
        loading={loading}
        locale={{ emptyText: empty }}
        renderItem={(item) => (
          <List.Item>
            <SimpleCard
              title={item.name}
              description={item.description}
              updatedAt={item.updatedAt}
              link={`/dashboard/jobs/${item.id}`}
            />
          </List.Item>
        )}
      />
      <JobCreationDrawer
        visible={drawerVisible}
        onCancel={handleCloseDrawer}
        testSuiteId={testSuiteId}
      />
    </div>
  );
}

JobList.propTypes = {
  testSuiteId: PropTypes.string,
};

export default React.memo(JobList);
