import React from 'react';
import PropTypes from 'prop-types';
import './ItemWrapper.less';

export default function ItemWrapper({ children }) {
  return <div className="item-wrapper">{children}</div>;
}

ItemWrapper.propTypes = {
  children: PropTypes.node.isRequired,
};
