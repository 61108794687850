import React, { useState, useEffect } from 'react';
import {
  Input,
  Form,
  Tabs,
  Radio,
  InputNumber,
  Select,
  Button,
  Tag,
  Typography,
  TimePicker,
} from 'antd';
import { useTranslation, Trans } from 'react-i18next';
import PropTypes from 'prop-types';
import range from 'lodash/range';
import uniq from 'lodash/uniq';
import upperFirst from 'lodash/upperFirst';
import isEqual from 'lodash/isEqual';
import cronstrue from 'cronstrue/i18n';
import { union } from 'lodash';

const { TabPane } = Tabs;
const { Option } = Select;
const { Text, Paragraph } = Typography;

// Fix Trans issue
// eslint-disable-next-line react/prop-types
const IntervalFormItem = ({ id, min, max, onChange }) => (
  <Form.Item name={[id, 'interval']} noStyle initialValue={1}>
    <InputNumber min={min} max={max} size="small" onChange={onChange} />
  </Form.Item>
);

function ScheduleSection({
  schedules: _schedules,
  connectedClientIds,
  selectedClientIds,
  setFieldsValue,
}) {
  const { i18n } = useTranslation();
  const { language } = i18n;
  const [schedules, setSchedules] = useState(_schedules);
  const [previewSchedule, setPreviewSchedule] = useState();
  const [selectedItems, setSelectedItems] = useState(selectedClientIds);
  const { t } = useTranslation();
  const [form] = Form.useForm();

  const filteredOptions = union(selectedClientIds, connectedClientIds).filter(
    (o) => !selectedItems.includes(o)
  );

  useEffect(() => {
    setFieldsValue({
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    });
  }, [setFieldsValue]);

  useEffect(() => {
    setFieldsValue({
      schedules: _schedules,
    });
  }, [_schedules, setFieldsValue]);

  useEffect(() => {
    setFieldsValue({
      clientIds: selectedClientIds,
    });
  }, [selectedClientIds, setFieldsValue]);

  // DOM	The day of the month (1–31)
  const DOMS = range(1, 32);
  // MONTH	The month (1–12)
  const MONTHS = range(1, 13);
  // DOW	The day of the week (0–7) where 0 and 7 are Sunday.
  const DOWS = range(0, 7);

  const data = [
    {
      key: 'hour',
      interval: { min: 1, max: 23 },
    },
    {
      key: 'dom',
      interval: { min: 1, max: 31 },
      values: DOMS,
    },
    {
      key: 'month',
      interval: { min: 1, max: 12 },
      values: MONTHS,
    },
    {
      key: 'dow',
      interval: { min: 1, max: 7 },
      values: DOWS,
    },
  ];

  const handleChangeRadio = (key, value) => {
    form.setFieldsValue({ [key]: { type: value } });
  };

  function getRanges(array) {
    const ranges = [];
    let rstart;
    let rend;

    for (let i = 0; i < array.length; i += 1) {
      rstart = array[i];
      rend = rstart;
      while (array[i + 1] - array[i] === 1) {
        rend = array[i + 1]; // increment the index if the numbers sequential
        i += 1;
      }
      ranges.push(rstart === rend ? `${rstart}` : `${rstart}-${rend}`);
    }
    return ranges;
  }

  const parse = (target = {}) => {
    let _target = '*';
    if (target.key === 'hour') {
      _target = '0 *';
    }

    if (target.type === 1) {
      if (target.range) {
        if (target.key === 'hour') {
          const minutes = target.range.minutes();
          const hour = target.range.hour();
          _target = `${minutes} ${hour}`;
        } else {
          _target = getRanges(target.range.sort((a, b) => a - b)).join(',');
          _target = _target === '' ? '*' : _target;
        }
      }
    } else if (target.type === 2) {
      const interval = target.interval === 1 ? '*' : `*/${target.interval}`;
      if (target.key === 'hour') {
        _target = `0 ${interval}`;
      } else {
        _target = interval;
      }
    }
    return _target;
  };

  const generateExpression = async () => {
    const { hour, dom, month, dow } = await form.getFieldsValue([
      'hour',
      'dom',
      'month',
      'dow',
    ]);
    const _hour = parse(hour);
    const _dom = parse(dom);
    const _month = parse(month);
    const _dow = parse(dow);

    return `${_hour} ${_dom} ${_month} ${_dow}`;
  };

  const handleSubmit = async () => {
    try {
      const expression = await generateExpression();
      const newSchedules = uniq([...schedules, expression]);
      setSchedules(newSchedules);
      setFieldsValue({
        schedules: newSchedules,
      });
      form.resetFields();
      setPreviewSchedule(null);
    } catch (error) {
      console.log(error);
    }
  };

  const handleRemoveSchedule = (removedSchedule) => {
    const newSchedules = schedules.filter((tag) => tag !== removedSchedule);
    setSchedules(newSchedules);
    setFieldsValue({ schedules: newSchedules });
  };

  const renderLabel = (key, value) => {
    switch (key) {
      case 'dow':
      case 'month':
        return t(`${key}${value}`);
      default:
        return value;
    }
  };

  const handleChangeSchedule = async (key, type) => {
    handleChangeRadio(key, type);
    try {
      const expression = await generateExpression();
      setPreviewSchedule(expression);
    } catch (error) {
      console.log(error);
    }
  };

  const handleChangeClient = (items) => {
    setSelectedItems(items);
  };

  const handlePreventDefault = (e) => {
    e.preventDefault();
  };

  return (
    <>
      <Form form={form}>
        <Tabs defaultActiveKey="hour">
          {data.map(({ key, interval: { min, max }, values }) => (
            <TabPane tab={t(`${key}`)} key={key}>
              <Form.Item name={[key, 'key']} initialValue={key} noStyle />
              <Form.Item name={[key, 'type']} initialValue={1}>
                <Radio.Group
                  onChange={({ target: { value } }) => {
                    handleChangeRadio(key, value);
                    handleChangeSchedule();
                  }}
                  style={{ display: 'flex', flexDirection: 'column' }}
                >
                  <Radio value={1} style={{ marginBottom: 20 }}>
                    <Text>{t(`select${upperFirst(key)}`)}</Text>{' '}
                    <Form.Item name={[key, 'range']} noStyle>
                      {key === 'hour' ? (
                        <TimePicker
                          size="small"
                          format="HH:mm"
                          minuteStep={5}
                          onClick={handlePreventDefault}
                          onChange={() => {
                            handleChangeSchedule(key, 1);
                          }}
                        />
                      ) : (
                        <Select
                          mode="tags"
                          showSearch
                          style={{ width: 100 }}
                          onClick={handlePreventDefault}
                          onChange={() => {
                            handleChangeSchedule(key, 1);
                          }}
                          size="small"
                        >
                          {values.map((value) => (
                            <Option key={`${key}${value}`} value={value}>
                              {renderLabel(key, value)}
                            </Option>
                          ))}
                        </Select>
                      )}
                    </Form.Item>{' '}
                  </Radio>
                  <Radio value={2}>
                    <Trans i18nKey={`every${upperFirst(key)}`}>
                      <IntervalFormItem
                        {...{
                          id: key,
                          min,
                          max,
                          onChange: () => {
                            handleChangeSchedule(key, 2);
                          },
                        }}
                      />
                    </Trans>
                  </Radio>
                </Radio.Group>
              </Form.Item>
            </TabPane>
          ))}
        </Tabs>
      </Form>
      {previewSchedule && (
        <Paragraph>
          <Text strong>{t('preview')}: </Text>
          <Text>
            {cronstrue.toString(previewSchedule, { locale: language })}
          </Text>
        </Paragraph>
      )}
      <Button type="primary" onClick={handleSubmit}>
        {t('addSchedule')}
      </Button>
      <Form.Item
        name="schedules"
        dependencies={['clientIds']}
        rules={[
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (getFieldValue('clientIds')?.length && !value.length) {
                return Promise.reject(new Error(t('requiredSchedule')));
              }
              return Promise.resolve();
            },
          }),
        ]}
      >
        {!schedules?.length && (
          <Text type="warning">{t('thereIsNoScheduleAdded')}</Text>
        )}
        {schedules.map((schedule) => (
          <Tag
            key={schedule}
            closable
            color="green"
            onClose={() => handleRemoveSchedule(schedule)}
          >
            {cronstrue.toString(schedule, { locale: language })}
          </Tag>
        ))}
      </Form.Item>
      <Form.Item name="timeZone" noStyle>
        <Input style={{ display: 'none' }} />
      </Form.Item>
      <Form.Item
        name="clientIds"
        label={t('clientsExecuteJob')}
        dependencies={['schedules']}
        rules={[
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (getFieldValue('schedules')?.length && !value.length) {
                return Promise.reject(new Error(t('selectClients')));
              }
              return Promise.resolve();
            },
          }),
        ]}
      >
        <Select
          mode="multiple"
          placeholder={t('selectClients')}
          value={selectedItems}
          onChange={handleChangeClient}
        >
          {filteredOptions.map((item) => (
            <Select.Option key={item} value={item}>
              {item}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
    </>
  );
}

ScheduleSection.propTypes = {
  schedules: PropTypes.array,
  connectedClientIds: PropTypes.array,
  selectedClientIds: PropTypes.array,
  setFieldsValue: PropTypes.func.isRequired,
};

ScheduleSection.defaultProps = {
  schedules: [],
  connectedClientIds: [],
  selectedClientIds: [],
};

export default React.memo(ScheduleSection, isEqual);
