import React from 'react';
import PropTypes from 'prop-types';
import { Redirect } from '@reach/router';
import DownloadPage from 'containers/DownloadPage';
import DocsPage from 'containers/DocsPage';
import SettingsPage from 'containers/Settings';
import PrivateRoute from 'containers/PrivateRoute';
import SharedPage from 'containers/SharedPage';
import ProjectsPage from 'containers/ProjectsPage';
import FadeTransitionRouter from 'components/FadeTransitionRouter';
import TestSuiteDetailPage from 'containers/TestSuiteDetailPage';
import TestCaseDetailPage from 'containers/TestCaseDetailPage';
import AppStoragePage from 'containers/AppStoragePage';
import JobList from 'containers/Jobs/JobList';
import JobDetail from 'containers/Jobs/JobDetail';
import useLocalizedPath from 'hooks/useLocalizedPath';
import ProjectDetailPage from './ProjectDetailPage';

// TODO: Without this one, will occur some bug relating to layout.
import 'styles/app.global.css';
import 'styles/github-gist-theme.global.css';

/* ConnectedRouter will use the store from Provider automatically */
const DashboardRoutes = ({ location }) => {
  const localizedPath = useLocalizedPath('dashboard');

  return (
    <SharedPage location={location}>
      <FadeTransitionRouter basepath={localizedPath}>
        <Redirect from="/" to="projects" noThrow />
        <PrivateRoute path="projects" component={ProjectsPage} />
        <PrivateRoute
          path="projects/:projectId"
          component={ProjectDetailPage}
        />
        <PrivateRoute
          path="projects/:projectId/application-storage"
          component={AppStoragePage}
        />
        <PrivateRoute
          path="test-suites/:testSuiteId"
          component={TestSuiteDetailPage}
        />
        <PrivateRoute
          path="test-cases/:testCaseId"
          component={TestCaseDetailPage}
        />
        <PrivateRoute
          path="test-suites/:testSuiteId/jobs"
          component={JobList}
        />
        <PrivateRoute path="jobs/:jobId" component={JobDetail} />
        <PrivateRoute path="settings" component={SettingsPage} />
        <PrivateRoute path="download" component={DownloadPage} />
        <PrivateRoute path="tutorial" component={DocsPage} />
      </FadeTransitionRouter>
    </SharedPage>
  );
};

DashboardRoutes.propTypes = {
  location: PropTypes.object.isRequired,
};

export default DashboardRoutes;
