import { groupBy } from 'lodash';
import { currentJobIdSelector } from 'redux/Jobs/selector';
import {
  loadingSelectorFactory,
  itemsByContainerIdSelectorFactory,
} from 'redux/selectors';
import { createSelector } from 'reselect';

const KEY = 'deviceResults';

export const groupedDeviceResultsSelector = createSelector(
  currentJobIdSelector,
  itemsByContainerIdSelectorFactory(KEY),
  (currentJobId, itemsByContainerIdFilter) => {
    const items = itemsByContainerIdFilter(currentJobId);

    return Object.entries(
      groupBy(
        items.sort((a, b) => b.build - a.build),
        'clientId'
      )
    );
  }
);

export const deviceResultLoadingSelector = loadingSelectorFactory(KEY);
