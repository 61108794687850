/* eslint-disable react/no-unescaped-entities */
/* eslint-disable max-len */
import React from 'react';
import { Alert, Button, Tag, Typography } from 'antd';
import useScrollToHash from 'hooks/useScrollToHash';
import { Trans } from 'gatsby-plugin-react-i18next';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import { FileSyncOutlined, PlusOutlined } from '@ant-design/icons';

const { Title, Paragraph, Text } = Typography;

function CreateAndExecuteJob() {
  useScrollToHash();

  const data = useStaticQuery(graphql`
    query {
      testSuiteDetail2: file(
        relativePath: { eq: "tutorial/test-suite-detail2.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1024) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      jobManagement: file(relativePath: { eq: "tutorial/job-management.png" }) {
        childImageSharp {
          fluid(maxWidth: 1024) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      jobManagement2: file(
        relativePath: { eq: "tutorial/job-management-2.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1024) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      jobCreation1: file(relativePath: { eq: "tutorial/job-creation-1.png" }) {
        childImageSharp {
          fluid(maxWidth: 1024) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      jobCreation2: file(relativePath: { eq: "tutorial/job-creation-2.png" }) {
        childImageSharp {
          fluid(maxWidth: 1024) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      jobCreation3: file(relativePath: { eq: "tutorial/job-creation-3.png" }) {
        childImageSharp {
          fluid(maxWidth: 1024) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      jobDetail: file(relativePath: { eq: "tutorial/job-detail.png" }) {
        childImageSharp {
          fluid(maxWidth: 1024) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      startJobBtn: file(relativePath: { eq: "tutorial/start-job-btn.png" }) {
        childImageSharp {
          fluid(maxWidth: 1024) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      connectedJobs: file(relativePath: { eq: "tutorial/connected-jobs.png" }) {
        childImageSharp {
          fluid(maxWidth: 1024) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      testCaseResultTable: file(
        relativePath: { eq: "tutorial/test-case-result-table.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1024) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      testCaseResultDetail: file(
        relativePath: { eq: "tutorial/test-case-result-detail.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1024) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <>
      <Title level={2} id="create-and-execute-job">
        <Trans ns="tutorial" i18nKey="create-and-execute-job" />
      </Title>
      <Title level={3} id="create-job">
        <Trans ns="tutorial" i18nKey="create-job" />
      </Title>
      <Paragraph>
        <Trans
          ns="tutorial"
          i18nKey="from-the-test-suite-details-screen-click-job-management"
        >
          <Text strong />
          <Button type="primary" size="small" icon={<FileSyncOutlined />} />
        </Trans>
      </Paragraph>
      <Img
        fluid={data?.testSuiteDetail2?.childImageSharp?.fluid}
        style={{ width: '70%' }}
        alt="test-suite-detail-2"
      />
      <Paragraph>
        <Trans
          ns="tutorial"
          i18nKey="from-the-job-management-screen-click-the-create-now-button-or-the-plus-button-in-the-upper-right-to-create-a-job"
        >
          <Text strong />
          <Button type="primary" size="small" />
          <Button
            type="primary"
            size="small"
            icon={<PlusOutlined />}
            shape="circle"
          />
        </Trans>
      </Paragraph>
      <Img
        fluid={data?.jobManagement?.childImageSharp?.fluid}
        style={{ width: '70%' }}
        alt="job-management"
      />
      <Paragraph>
        <ol>
          <li>
            <Text strong>
              <Trans ns="tutorial" i18nKey="job-information" />
              {': '}
            </Text>
            <Trans
              ns="tutorial"
              i18nKey="enter-an-appropriate-job-name-and-description"
            />
          </li>
          <li>
            <Text strong>
              <Trans ns="tutorial" i18nKey="platform" />
              {': '}
            </Text>
            <Trans
              ns="tutorial"
              i18nKey="select-the-type-of-device-to-run-test"
            />
          </li>
          <li>
            <Text strong>
              <Trans ns="tutorial" i18nKey="application-type" />
              {': '}
            </Text>
            <Trans ns="tutorial" i18nKey="set-the-application-to-use" />
          </li>
          <li>
            <Text strong>
              <Trans ns="tutorial" i18nKey="select-test-case" />
              {': '}
            </Text>
            <Trans ns="tutorial" i18nKey="select-the-test-case-to-run" />
          </li>
          <li>
            <Text strong>
              <Trans ns="tutorial" i18nKey="execute-periodically-optional" />
              {': '}
            </Text>
            <Trans
              ns="tutorial"
              i18nKey="set-time-for-executing-periodically-and-clients-you-want-the-job-run"
            />
            <Paragraph>
              <Alert
                banner
                showIcon
                message={
                  <Trans
                    ns="tutorial"
                    i18nKey="even-if-you-do-not-set-the-time-it-is-possible-to-execute-the-test-from-the-job-management-screen"
                  />
                }
                type="warning"
                style={{ width: 700 }}
              />
            </Paragraph>
          </li>
          <li>
            <Text strong>
              <Trans
                ns="tutorial"
                i18nKey="notification-after-build-optional"
              />
              {': '}
            </Text>
            <Trans
              ns="tutorial"
              i18nKey="if-you-want-to-receive-test-start-end-fail-notification-enter-the-destination"
            />
          </li>
        </ol>
      </Paragraph>
      <Img
        fluid={data?.jobCreation1?.childImageSharp?.fluid}
        style={{ width: '70%' }}
        alt="job-create-1"
      />
      <Img
        fluid={data?.jobCreation2?.childImageSharp?.fluid}
        style={{ width: '70%' }}
        alt="job-create-2"
      />
      <Img
        fluid={data?.jobCreation3?.childImageSharp?.fluid}
        style={{ width: '70%' }}
        alt="job-create-3"
      />
      <Title level={3} id="execute-job">
        <Trans ns="tutorial" i18nKey="execute-job" />
      </Title>
      <Paragraph>
        <Trans
          ns="tutorial"
          i18nKey="click-see-details-on-the-job-management-screen-to-move-to-the-job-detail-screen"
        >
          <Button type="link" style={{ padding: '4px 0px' }} />
          <Text strong />
          <Text strong />
        </Trans>
      </Paragraph>
      <Img
        fluid={data?.jobManagement2?.childImageSharp?.fluid}
        style={{ width: '70%' }}
        alt="job-management-2"
      />
      <Paragraph>
        <Trans
          ns="tutorial"
          i18nKey="click-start-job-to-run-the-job-on-the-selected-client"
        >
          <Button size="small" type="primary" />
        </Trans>
      </Paragraph>
      <Img
        fluid={data?.startJobBtn?.childImageSharp?.fluid}
        style={{ width: '70%' }}
        alt="start-job-btn"
      />
      <Paragraph>
        <Trans
          ns="tutorial"
          i18nKey="in-case-of-periodic-execution-it-will-be-executed-automatically"
        />
      </Paragraph>
      <Paragraph>
        <Alert
          banner
          showIcon
          message={
            <Trans
              ns="tutorial"
              i18nKey="at-this-time-the-moppium-client-must-be-running-and-connected-to-the-device"
            />
          }
          type="warning"
          style={{ width: 630 }}
        />
      </Paragraph>
      <Paragraph>
        <Trans
          ns="tutorial"
          i18nKey="make-sure-that-the-mobile-device-is-connected-and-displayed-in-the-device-management-of-moppium-client-and-the-status-is-running"
        >
          <Text strong />
          <Tag color="orange" style={{ marginRight: 'unset' }} />
          <Tag color="green" style={{ marginRight: 'unset' }} />
        </Trans>
      </Paragraph>
      <Img
        fluid={data?.jobDetail?.childImageSharp?.fluid}
        style={{ width: '70%' }}
        alt="job-detail"
      />
      <Paragraph>
        <Trans
          ns="tutorial"
          i18nKey="you-can-also-check-scheduled-jobs-on-the-connected-jobs-screen-of-moppium-client"
        >
          <Text strong />
          <Tag color="orange" style={{ marginRight: 'unset' }} />
        </Trans>
      </Paragraph>
      <Img
        fluid={data?.connectedJobs?.childImageSharp?.fluid}
        style={{ width: '70%' }}
        alt="connected-jobs"
      />
      <Title level={3} id="check-job-execution-results">
        <Trans ns="tutorial" i18nKey="check-job-execution-results" />
      </Title>
      <Paragraph>
        <Trans
          ns="tutorial"
          i18nKey="from-the-job-detail-page-you-can-check-the-execution-results-for-each-build-and-device"
        >
          <Text strong />
        </Trans>
      </Paragraph>
      <Img
        fluid={data?.jobDetail?.childImageSharp?.fluid}
        style={{ width: '70%' }}
        alt="job-detail"
      />
      <Paragraph>
        <Trans
          ns="tutorial"
          i18nKey="you-can-check-the-result-for-each-test-case-by-clicking--in-the-execution-result-for-each-build"
        >
          <Button icon={<PlusOutlined />} size="small" />
        </Trans>
      </Paragraph>
      <Img
        fluid={data?.testCaseResultTable?.childImageSharp?.fluid}
        style={{ width: '70%' }}
        alt="test-case-result-table"
      />
      <Paragraph>
        <Trans
          ns="tutorial"
          i18nKey="click-details-at-the-right-end-to-display-the-steps-of-the-test-case-execution-result"
        >
          <Button type="link" size="small" style={{ padding: '4px 0px' }} />
        </Trans>
      </Paragraph>
      <Paragraph>
        <Trans
          ns="tutorial"
          i18nKey="in-case-of-failure-the-error-part-is-displayed-in-red-and-you-can-check-the-error-log"
        />
      </Paragraph>
      <Img
        fluid={data?.testCaseResultDetail?.childImageSharp?.fluid}
        style={{ width: '70%' }}
        alt="test-case-result-detail"
      />
    </>
  );
}

export default React.memo(CreateAndExecuteJob);
