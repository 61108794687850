import React, { useEffect, useState, useCallback } from 'react';
import { Typography, List, Card, Button, Menu, Dropdown, Modal } from 'antd';
import { useTranslation } from 'react-i18next';
import ActionBar from 'components/ActionBar/ActionBarView';
import { useDispatch, useSelector } from 'react-redux';
import { getAppleTeams, deleteAppleTeam } from 'redux/AppleTeams/slice';
import {
  appleTeamsSelector,
  appleTeamLoadingSelector,
} from 'redux/AppleTeams/selectors';
import { EllipsisOutlined } from '@ant-design/icons';
import { useI18next } from 'gatsby-plugin-react-i18next';
import SEO from 'components/SEO';
import AppleTeamCreationDrawer from './AppleTeamCreationDrawer';
import AppleTeamEditionDrawer from './AppleTeamEditionDrawer';

const { Text, Paragraph } = Typography;

export default function Settings() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { navigate } = useI18next();

  const [creationVisible, setCreationVisible] = useState(false);
  const [editionVisible, setEditionVisible] = useState(false);
  const [currentAppleTeam, setCurrentAppleTeam] = useState({});
  const [confirmDeleteVisible, setConfirmDeleteVisible] = useState(false);

  useEffect(() => {
    dispatch(getAppleTeams());
  }, [dispatch]);

  const handleBack = useCallback(() => {
    navigate('/dashboard/projects');
  }, [navigate]);

  const handleCreate = useCallback(() => {
    setCreationVisible(true);
  }, []);

  const handleCancelCreationDrawer = useCallback(() => {
    setCreationVisible(false);
  }, []);

  const handleCancelEditionDrawer = useCallback(() => {
    setEditionVisible(false);
  }, []);

  const data = useSelector(appleTeamsSelector);
  const loading = useSelector(appleTeamLoadingSelector);

  const handleClickMenu = ({ key }) => {
    switch (key) {
      case 'update': {
        setEditionVisible(true);
        break;
      }
      case 'remove': {
        setConfirmDeleteVisible(true);
        break;
      }
      default:
        break;
    }
  };

  const handleClickDropDown = useCallback((item) => {
    setCurrentAppleTeam(item);
  }, []);

  const handleCancelDelete = useCallback(() => {
    setConfirmDeleteVisible(false);
  }, []);

  const handleConfirmDelete = useCallback(() => {
    if (currentAppleTeam.id) {
      dispatch(deleteAppleTeam({ id: currentAppleTeam.id }));
      handleCancelDelete();
    }
  }, [currentAppleTeam.id, dispatch, handleCancelDelete]);

  const menu = (
    <Menu onClick={handleClickMenu} style={{ width: 80 }}>
      <Menu.Item key="update">{t('update')}</Menu.Item>
      <Menu.Item key="remove" danger>
        {t('remove')}
      </Menu.Item>
    </Menu>
  );

  return (
    <div className="layout">
      <SEO
        title={t('appleDeveloperSetting')}
        description={t('SEODescription')}
      />
      <ActionBar
        title={t('appleDeveloperSetting')}
        onBack={handleBack}
        onCreate={handleCreate}
        createTitle={t('createAppleTeam')}
      />
      <List
        loading={loading}
        dataSource={data}
        grid={{ gutter: 16, column: 4 }}
        renderItem={(item) => (
          <List.Item>
            <Card
              title={item.teamName}
              extra={
                <Dropdown overlay={menu}>
                  <Button
                    type="dashed"
                    shape="circle"
                    icon={<EllipsisOutlined />}
                    size={12}
                    onClick={() => handleClickDropDown(item)}
                  />
                </Dropdown>
              }
            >
              <Paragraph>
                <Text code>{t('teamId')}:</Text> <Text>{item.teamId}</Text>
              </Paragraph>
            </Card>
          </List.Item>
        )}
      />
      <AppleTeamCreationDrawer
        visible={creationVisible}
        onCancel={handleCancelCreationDrawer}
      />
      <AppleTeamEditionDrawer
        visible={editionVisible}
        onCancel={handleCancelEditionDrawer}
        data={currentAppleTeam}
      />
      <Modal
        visible={confirmDeleteVisible}
        onOk={handleConfirmDelete}
        onCancel={handleCancelDelete}
        okText={t('confirm')}
        cancelText={t('cancel')}
        confirmLoading={loading}
      >
        {t('confirmDelete')}
      </Modal>
    </div>
  );
}
