import {
  listSelectorFactory,
  loadingSelectorFactory,
  dataByIdSelectorFactory,
} from 'redux/selectors';

const KEY = 'appleTeams';

export const appleTeamsSelector = listSelectorFactory(KEY);

export const appleTeamLoadingSelector = loadingSelectorFactory(KEY);

export const appleTeamByIdSelector = dataByIdSelectorFactory(KEY);
