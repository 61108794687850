import React, { useCallback, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import {
  updateProject,
  deleteProject,
  getProjectDetail,
} from 'redux/Projects/slice';
import {
  currentProjectSelector,
  projectLoadingSelector,
} from 'redux/Projects/selectors';
import { getTestSuites } from 'redux/TestSuites/slice';
import { useI18next } from 'gatsby-plugin-react-i18next';
import {
  testSuitesByProjectIdSelector,
  testSuiteLoadingByProjectIdSelector,
} from 'redux/TestSuites/selectors';
import { Empty, Button, Typography, Skeleton } from 'antd';
import isEmpty from 'lodash/isEmpty';
import upperCase from 'lodash/upperCase';
import SEO from 'components/SEO';
import { useTranslation } from 'react-i18next';
import ActionBar from 'components/ActionBar';
import Label from 'components/Styled/Label';
import ProjectFormEditionDrawer from 'components/Projects/ProjectFormEditionDrawer';
import TestSuiteList from 'components/TestSuites/TestSuiteList';
import SharedLayout from 'components/Styled/SharedLayout';
import BasicInformation from 'components/Display/BasicInformation';
import { CloudOutlined } from '@ant-design/icons';
import TestSuiteCreationDrawer from 'containers/TestSuites/TestSuiteCreationDrawer';

const { Title, Paragraph } = Typography;

function ProjectDetailPage(props) {
  const { projectId: id } = props;
  const dispatch = useDispatch();
  const { navigate } = useI18next();
  const { t } = useTranslation();
  const onDeleteProject = useCallback(() => {
    dispatch(deleteProject({ id }));
  }, [dispatch, id]);

  const onGetProjectDetail = useCallback(() => {
    dispatch(getProjectDetail({ id }));
  }, [dispatch, id]);

  const onGetTestSuites = useCallback(() => {
    dispatch(getTestSuites({ id }));
  }, [dispatch, id]);

  const handleBack = useCallback(() => {
    navigate('/dashboard/projects');
  }, [navigate]);

  const data = useSelector(currentProjectSelector);
  const loading = useSelector(projectLoadingSelector);
  const testSuitesByProjectIdFilter = useSelector(
    testSuitesByProjectIdSelector
  );
  const items = testSuitesByProjectIdFilter(id);
  const testSuiteLoadingByProjectIdFilter = useSelector(
    testSuiteLoadingByProjectIdSelector
  );
  const itemsLoading = testSuiteLoadingByProjectIdFilter(id);

  const handlePushProjectStorage = useCallback(() => {
    navigate(`/dashboard/projects/${id}/application-storage`);
  }, [id, navigate]);

  const [testSuiteVisible, setTestSuiteVisible] = useState();
  const [projectVisible, setProjectVisible] = useState();

  useEffect(() => {
    onGetProjectDetail({ id });
    onGetTestSuites({ id });
  }, [id, onGetProjectDetail, onGetTestSuites]);

  // TODO: Test Suites Actions
  const handleCancelTestSuite = useCallback(() => {
    setTestSuiteVisible(false);
  }, []);

  const handleShowTestSuite = useCallback(() => {
    setTestSuiteVisible(true);
  }, []);

  // TODO: Project Actions
  const handleCancelProject = useCallback(() => {
    setProjectVisible(false);
  }, []);

  const handleShowProject = () => {
    if (!isEmpty(data)) {
      setProjectVisible(true);
    } else {
      return null;
    }
  };

  const handleEditProject = useCallback(
    async (payload) => {
      await dispatch(updateProject(payload));
    },
    [dispatch]
  );

  const handleDeleteProject = () => {
    if (!isEmpty(data)) {
      onDeleteProject({ id });
      handleBack();
    } else {
      return null;
    }
  };

  const handleCreate = useCallback(() => {
    if (!isEmpty(data)) {
      handleShowTestSuite();
    } else {
      return null;
    }
  }, [data, handleShowTestSuite]);

  const renderExpandActions = () => (
    <Button
      type="primary"
      size="large"
      icon={<CloudOutlined />}
      style={{ marginLeft: 10 }}
      onClick={handlePushProjectStorage}
    >
      {t('appStorage')}
    </Button>
  );

  return (
    <SharedLayout>
      <SEO title={t('projectDetail')} description={t('SEODescription')} />
      <ActionBar
        title={t('projectDetail')}
        renderExpandActions={renderExpandActions}
        onBack={handleBack}
        createTitle={t('createTestSuite')}
        onCreate={handleCreate}
        editTitle={t('editProject')}
        onEdit={handleShowProject}
        deleteTitle={t('deleteProject')}
        onDelete={handleDeleteProject}
      />
      <Skeleton loading={loading} paragraph={{ rows: 10 }}>
        <Title level={2}>{data.name}</Title>
        <BasicInformation
          updatedAt={data.updatedAt}
          description={data.description}
        />
        <Paragraph>
          <Label>{upperCase(t('testSuiteList'))}: </Label>
        </Paragraph>
        <TestSuiteList
          data={items}
          loading={itemsLoading}
          onShowTestSuiteCreationForm={handleShowTestSuite}
        />
      </Skeleton>
      {isEmpty(data) && !loading && <Empty />}
      <TestSuiteCreationDrawer
        projectId={id}
        visible={testSuiteVisible}
        onCancel={handleCancelTestSuite}
      />
      <ProjectFormEditionDrawer
        visible={projectVisible}
        data={data}
        onCancel={handleCancelProject}
        onSubmit={handleEditProject}
      />
    </SharedLayout>
  );
}

ProjectDetailPage.propTypes = {
  projectId: PropTypes.string,
};

export default React.memo(ProjectDetailPage);
