import React from 'react';
import PropTypes from 'prop-types';
import './index.less';

export default function CardLabel({ children }) {
  return <span className="card-label">{children}</span>;
}

CardLabel.propTypes = {
  children: PropTypes.node.isRequired,
};
