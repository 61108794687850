import React, { useCallback } from 'react';
import { Form, Input } from 'antd';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import SharedDrawer from 'components/SharedDrawer';
import { createAppleTeam } from 'redux/AppleTeams/slice';
import { noWhiteSpaceAtStartAndEnd } from 'utils/validation';
import { useDispatch, useSelector } from 'react-redux';
import { appleTeamLoadingSelector } from 'redux/AppleTeams/selectors';

function AppleTeamCreationDrawer({ visible, onCancel }) {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const { validateFields, resetFields } = form;
  const dispatch = useDispatch();

  const loading = useSelector(appleTeamLoadingSelector);

  const handleCancel = useCallback(() => {
    resetFields();
    onCancel();
  }, [onCancel, resetFields]);

  const handleSubmit = useCallback(async () => {
    try {
      const values = await validateFields();
      await dispatch(createAppleTeam(values));
      handleCancel();
    } catch (error) {
      // Do nothing
    }
  }, [dispatch, handleCancel, validateFields]);

  return (
    <SharedDrawer
      visible={visible}
      title={t('createAppleTeam')}
      okText={t('create')}
      cancelText={t('cancel')}
      onCancel={handleCancel}
      onSubmit={handleSubmit}
      submitLoading={loading}
    >
      <Form form={form}>
        <Form.Item
          name="teamName"
          rules={[
            {
              required: true,
              message: t('requiredTeamName'),
              whitespace: true,
            },
            {
              max: 50,
              message: t('validate.max', {
                key: t('teamName'),
                value: 50,
              }),
            },
            {
              validator: (_, value) => noWhiteSpaceAtStartAndEnd(value, t),
            },
          ]}
        >
          <Input placeholder="ABC Team" addonBefore={t('teamName')} />
        </Form.Item>
        <Form.Item
          name="teamId"
          rules={[
            {
              required: true,
              message: t('requiredTeamId'),
              whitespace: true,
            },
            {
              len: 10,
              message: t('validate.len', {
                key: t('teamId'),
                value: 10,
              }),
            },
            {
              validator: (_, value) => noWhiteSpaceAtStartAndEnd(value, t),
            },
          ]}
        >
          <Input placeholder="ABCDE12345" addonBefore={t('teamId')} />
        </Form.Item>
      </Form>
    </SharedDrawer>
  );
}

AppleTeamCreationDrawer.propTypes = {
  visible: PropTypes.bool,
  onCancel: PropTypes.func.isRequired,
};

export default React.memo(AppleTeamCreationDrawer);
