import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Input, Form } from 'antd';
import { noWhiteSpaceAtStartAndEnd } from 'utils/validation';
import { useTranslation } from 'react-i18next';
import CustomInput from 'components/Styled/CustomInput';

function TestSuiteInfoSection({ name, description, onSetFieldsValue }) {
  const { t } = useTranslation();

  useEffect(() => {
    if (onSetFieldsValue) {
      onSetFieldsValue({ name, description });
    }
  }, [description, name, onSetFieldsValue]);

  return (
    <>
      <Form.Item
        name="name"
        initialValue={name}
        rules={[
          {
            required: true,
            message: t('requiredTestSuiteName'),
            whitespace: true,
          },
          {
            max: 50,
            message: t('validate.max', {
              key: t('name'),
              value: 50,
            }),
          },
          {
            validator: (_, value) => noWhiteSpaceAtStartAndEnd(value, t),
          },
        ]}
      >
        <Input addonBefore={t('name')} />
      </Form.Item>
      <Form.Item>
        <CustomInput label={t('description')}>
          <Form.Item
            noStyle
            name="description"
            initialValue={description}
            rules={[
              {
                max: 200,
                message: t('validate.max', {
                  key: t('description'),
                  value: 200,
                }),
              },
              {
                validator: (_, value) => noWhiteSpaceAtStartAndEnd(value, t),
              },
            ]}
          >
            <Input.TextArea className="remove-border-left" />
          </Form.Item>
        </CustomInput>
      </Form.Item>
    </>
  );
}

TestSuiteInfoSection.propTypes = {
  name: PropTypes.string,
  description: PropTypes.string,
  onSetFieldsValue: PropTypes.func,
};

export default React.memo(TestSuiteInfoSection);
