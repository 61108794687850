import React, { useCallback, useEffect } from 'react';
import { Form, Input } from 'antd';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import SharedDrawer from 'components/SharedDrawer';
import { updateAppleTeam } from 'redux/AppleTeams/slice';
import { noWhiteSpaceAtStartAndEnd } from 'utils/validation';
import { useDispatch, useSelector } from 'react-redux';
import { appleTeamLoadingSelector } from 'redux/AppleTeams/selectors';

function AppleTeamEditionDrawer({ visible, onCancel, data }) {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const { validateFields, resetFields, setFieldsValue } = form;
  const dispatch = useDispatch();

  useEffect(() => {
    setFieldsValue({
      teamName: data.teamName,
      teamId: data.teamId,
    });
  }, [data.teamId, data.teamName, setFieldsValue]);

  const loading = useSelector(appleTeamLoadingSelector);

  const handleCancel = useCallback(() => {
    resetFields();
    onCancel();
  }, [onCancel, resetFields]);

  const handleSubmit = useCallback(async () => {
    try {
      if (data.id) {
        const values = await validateFields();
        await dispatch(updateAppleTeam({ id: data.id, ...values }));
        handleCancel();
      }
    } catch (error) {
      console.error('handleSubmit error', error);
      // Do nothing
    }
  }, [data.id, dispatch, handleCancel, validateFields]);

  return (
    <SharedDrawer
      visible={visible}
      title={t('updateAppleTeam')}
      okText={t('update')}
      cancelText={t('cancel')}
      onCancel={handleCancel}
      onSubmit={handleSubmit}
      submitLoading={loading}
      destroyOnClose
    >
      <Form form={form}>
        <Form.Item
          name="teamName"
          initialValue={data.teamName}
          rules={[
            {
              required: true,
              message: t('requiredTeamName'),
              whitespace: true,
            },
            {
              max: 50,
              message: t('validate.max', {
                key: t('teamName'),
                value: 50,
              }),
            },
            {
              validator: (_, value) => noWhiteSpaceAtStartAndEnd(value, t),
            },
          ]}
        >
          <Input placeholder="ABC Team" addonBefore={t('teamName')} />
        </Form.Item>
        <Form.Item
          name="teamId"
          initialValue={data.teamId}
          rules={[
            {
              required: true,
              message: t('requiredTeamId'),
              whitespace: true,
            },
            {
              len: 10,
              message: t('validate.len', {
                key: t('teamId'),
                value: 10,
              }),
            },
            {
              validator: (_, value) => noWhiteSpaceAtStartAndEnd(value, t),
            },
          ]}
        >
          <Input placeholder="ABCDE12345" addonBefore={t('teamId')} />
        </Form.Item>
      </Form>
    </SharedDrawer>
  );
}

AppleTeamEditionDrawer.propTypes = {
  visible: PropTypes.bool,
  onCancel: PropTypes.func.isRequired,
  data: PropTypes.object,
};

export default React.memo(AppleTeamEditionDrawer);
