import React from 'react';
import Label from 'components/Styled/Label';
import PropTypes from 'prop-types';
import { Typography } from 'antd';
import upperCase from 'lodash/upperCase';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

const { Paragraph } = Typography;

export default function BasicInformation({ updatedAt, description }) {
  const { t } = useTranslation();

  return (
    <div>
      <Label>{upperCase(t('createdAt'))}:</Label>
      <Paragraph>{moment(updatedAt).format('llll')}</Paragraph>
      <Label>{upperCase(t('description'))}:</Label>
      <Paragraph ellipsis={{ rows: 3, expandable: true }}>
        {description}
      </Paragraph>
    </div>
  );
}

BasicInformation.propTypes = {
  updatedAt: PropTypes.string,
  description: PropTypes.string,
};
