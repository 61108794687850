import React from 'react';
import PropTypes from 'prop-types';
import './index.less';

export default function SharedLayout({ children }) {
  return <div className="shared-layout">{children}</div>;
}

SharedLayout.propTypes = {
  children: PropTypes.node.isRequired,
};
