import React, { useState, useCallback, useEffect } from 'react';
import { Steps, Form } from 'antd';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import SharedDrawer from 'components/SharedDrawer';
import isEqual from 'react-fast-compare';
import { updateTestSuite } from 'redux/TestSuites/slice';
import { useDispatch, useSelector } from 'react-redux';
import TestSuiteInfoSection from 'components/TestSuites/TestSuiteInfoSection';
import TestSuitePlatformEditionSection from 'components/TestSuites/TestSuitePlatformEditionSection';
import TestSuiteConfigSection from 'components/TestSuites/TestSuiteConfigSection';
import { appleTeamsSelector } from 'redux/AppleTeams/selectors';

const { Step } = Steps;

function TestSuiteEditionDrawer({ projectId, visible, data, onCancel }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const {
    resetFields,
    validateFields,
    setFieldsValue,
    getFieldValue,
    getFieldError,
  } = form;

  const {
    id,
    platformName,
    deviceType: _deviceType,
    target,
    name,
    description,
    fileId: _fileId,
    bundleId: _bundleId,
    appPackage: _appPackage,
    appActivity: _appActivity,
    teamId,
  } = data;

  const [currentStep, setCurrentStep] = useState(0);
  const [currentStepStatus, setCurrentStepStatus] = useState('process');

  const [deviceType, setDeviceType] = useState(_deviceType);

  const appleTeams = useSelector(appleTeamsSelector);

  useEffect(() => {
    if (_deviceType) {
      setDeviceType(_deviceType);
    }
  }, [_deviceType]);

  const [bundleId, setBundleId] = useState(_bundleId);
  useEffect(() => {
    setBundleId(_bundleId);
  }, [_bundleId]);

  const [fileId, setFileId] = useState(_fileId);
  useEffect(() => {
    setFileId(_fileId);
  }, [_fileId]);

  const [appPackage, setAppPackage] = useState(_appPackage);
  useEffect(() => {
    setAppPackage(_appPackage);
  }, [_appPackage]);

  const [appActivity, setAppActivity] = useState(_appActivity);
  useEffect(() => {
    setAppActivity(_appActivity);
  }, [_appActivity]);

  const handleCancel = useCallback(() => {
    resetFields();
    onCancel();
  }, [onCancel, resetFields]);

  const handleChangeStep = useCallback(
    (step) => {
      if (!getFieldValue('name')) {
        setCurrentStep(0);
        setCurrentStepStatus('error');
      } else if (step > 1 && getFieldError('target').length > 0) {
        setCurrentStep(1);
        setCurrentStepStatus('error');
      } else if (step > 1 && getFieldError('teamId').length > 0) {
        setCurrentStep(1);
        setCurrentStepStatus('error');
      } else if (
        step > 2 &&
        (getFieldError('fileId').length > 0 ||
          getFieldError('bundleId').length > 0 ||
          getFieldError('appActivity').length > 0 ||
          getFieldError('appPackage').length > 0)
      ) {
        setCurrentStep(2);
        setCurrentStepStatus('error');
      } else if (step > 3 && getFieldError('clientId').length > 0) {
        setCurrentStep(3);
        setCurrentStepStatus('error');
      } else {
        setCurrentStep(step);
        setCurrentStepStatus('process');
      }
    },
    [getFieldError, getFieldValue]
  );

  const handleSubmit = useCallback(async () => {
    try {
      const values = await validateFields();
      await dispatch(updateTestSuite({ id, ...values }));
      handleCancel();
    } catch (error) {
      handleChangeStep(999);
      // TODO: Do nothing
    }
  }, [dispatch, handleCancel, handleChangeStep, id, validateFields]);

  const renderStep0 = () => (
    <Step
      title={t('testSuiteInfo')}
      description={
        <TestSuiteInfoSection
          name={name}
          description={description}
          onSetFieldsValue={setFieldsValue}
        />
      }
    />
  );

  const handleChangeDeviceType = useCallback(
    (value) => {
      setDeviceType(value);
      setFieldsValue({ fileId: null });
      // Need to refresh selected id in App Storage
      setFileId(null);
    },
    [setFieldsValue]
  );

  const renderStep1 = () => (
    <Step
      title={t('platform')}
      description={
        <TestSuitePlatformEditionSection
          platformName={platformName}
          target={target}
          deviceType={deviceType}
          onChangeDeviceType={handleChangeDeviceType}
          appleTeams={appleTeams}
          teamId={teamId}
          onSetFieldsValue={setFieldsValue}
        />
      }
    />
  );

  const handleSelectFile = useCallback(
    (_id) => {
      setFieldsValue({ fileId: _id });
    },
    [setFieldsValue]
  );

  const renderStep2 = () => (
    <Step
      title={t('appType')}
      description={
        <TestSuiteConfigSection
          projectId={projectId}
          platformName={platformName}
          deviceType={deviceType}
          fileId={fileId}
          bundleId={bundleId}
          onSelectFile={handleSelectFile}
          appPackage={appPackage}
          appActivity={appActivity}
          onSetFieldsValue={setFieldsValue}
        />
      }
    />
  );

  return (
    <SharedDrawer
      visible={visible}
      title={t('editTestSuite')}
      okText={t('update')}
      cancelText={t('cancel')}
      onCancel={handleCancel}
      onSubmit={handleSubmit}
      destroyOnClose
    >
      <Form form={form} layout="vertical">
        <Steps
          current={currentStep}
          onChange={handleChangeStep}
          direction="vertical"
          status={currentStepStatus}
          style={{ marginBottom: 50 }}
        >
          {renderStep0()}
          {renderStep1()}
          {renderStep2()}
        </Steps>
      </Form>
    </SharedDrawer>
  );
}

TestSuiteEditionDrawer.propTypes = {
  visible: PropTypes.bool,
  projectId: PropTypes.string,
  data: PropTypes.object,
  onCancel: PropTypes.func.isRequired,
};

TestSuiteEditionDrawer.defaultProps = {
  data: {},
};

export default React.memo(TestSuiteEditionDrawer, isEqual);
