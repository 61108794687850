export default [
  {
    value: 'ios',
    label: 'ios',
    children: [
      {
        value: 'device',
        label: 'device',
        children: [
          // {
          //   value: 'browser',
          //   label: 'Safari',
          // },
          {
            value: 'app',
            label: 'application',
          },
        ],
      },
      {
        value: 'simulator',
        label: 'simulator',
        children: [
          // {
          //   value: 'browser',
          //   label: 'Safari',
          // },
          {
            value: 'app',
            label: 'application',
          },
        ],
      },
    ],
  },
  {
    value: 'android',
    label: 'android',
    children: [
      {
        value: 'deviceAndEmulator',
        label: 'deviceAndEmulator',
        children: [
          // {
          //   value: 'browser',
          //   label: 'Default browser',
          // },
          {
            value: 'app',
            label: 'application',
          },
        ],
      },
    ],
  },
];
