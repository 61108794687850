import React, { useCallback } from 'react';
import { Form } from 'antd';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import SharedDrawer from 'components/SharedDrawer';
import isEqual from 'react-fast-compare';
import SimpleForm from 'components/Forms/SimpleForm';

function ProjectFormCreationDrawer({ visible, onCancel, onSubmit }) {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const { validateFields, resetFields } = form;

  const handleCancel = useCallback(() => {
    resetFields();
    onCancel();
  }, [onCancel, resetFields]);

  const handleSubmit = useCallback(async () => {
    try {
      const values = await validateFields();
      onSubmit({ ...values });
      handleCancel();
    } catch (error) {
      // Do nothing
    }
  }, [handleCancel, onSubmit, validateFields]);

  return (
    <SharedDrawer
      visible={visible}
      title={t('createProject')}
      okText={t('create')}
      cancelText={t('cancel')}
      onCancel={handleCancel}
      onSubmit={handleSubmit}
    >
      <SimpleForm {...{ form }} />
    </SharedDrawer>
  );
}

ProjectFormCreationDrawer.propTypes = {
  visible: PropTypes.bool,
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  data: PropTypes.object,
};

ProjectFormCreationDrawer.defaultProps = {
  data: {},
};

export default React.memo(ProjectFormCreationDrawer, isEqual);
