import React, { useState } from 'react';
import PropTypes from 'prop-types';
import AppInfoParser from 'dockyard-app-info-parser';
import { AndroidFilled, AppleFilled, FileZipOutlined } from '@ant-design/icons';
import { Upload, message } from 'antd';
import isEmpty from 'lodash/isEmpty';
import { getFileCredentials } from 'redux/Files/api';
// TODO: How to use just S3 service out of the whole `aws-sdk`? #1120
// https://github.com/aws/aws-sdk-js/issues/1120#issuecomment-245820338
import S3 from 'aws-sdk/clients/s3';
import { config } from 'aws-sdk/lib/core'; // or any other `aws-sdk` export
import { v4 as uuidv4 } from 'uuid';
import { useTranslation } from 'react-i18next';

const { Dragger } = Upload;

const AppUploader = ({ projectId, onCreateFile }) => {
  const { t } = useTranslation();

  const [fileInfo, setFileInfo] = useState({});
  const [credentials, setCredentials] = useState({
    bucket: '',
    accessKeyId: '',
    secretAccessKey: '',
    sessionToken: '',
  });

  const handleBeforeUpload = async (file) => {
    const { name } = file;
    const fileExt = name.substr(name.lastIndexOf('.') + 1);

    const allowExtension = ['zip', 'apk', 'ipa'].some((ext) => ext === fileExt);
    if (!allowExtension) {
      message.error('This file type is not allowed.');
      return false;
    }

    try {
      // TODO: Get file info
      const parser = new AppInfoParser(file); // or xxx.ipa
      const info = await parser.parse();

      let version;
      let minOSVersion;
      let bundleId;
      let appName;
      let appPackage;
      let appActivity;

      if (fileExt === 'ipa' || fileExt === 'zip') {
        ({
          CFBundleExecutable: appName,
          CFBundleShortVersionString: version,
          CFBundleIdentifier: bundleId,
          MinimumOSVersion: minOSVersion,
        } = info);
      } else if (fileExt === 'apk') {
        ({
          versionName: version,
          package: appPackage,
          platformBuildVersionName: minOSVersion,
        } = info);
        const [activity] = info.application.activities;
        appActivity = activity.name;
        [appName] = activity.label;
      } else {
        message.error('Unsupported file.');
        return false;
      }

      setFileInfo({
        version,
        minOSVersion,
        bundleId,
        name,
        appName,
        type: fileExt,
        appPackage,
        appActivity,
      });

      // TODO: Get the temporary AWS Credentials
      const { data } = await getFileCredentials({
        type: 'projects',
        id: projectId,
      });

      setCredentials(data);

      return false;
    } catch (error) {
      message.error(error.message);
      return false;
    }
  };

  const handleChange = (info) => {
    const { status } = info.file;
    if (status !== 'uploading') {
      console.log(info.file, info.fileList);
    }
    if (status === 'done') {
      message.success(`${info.file.name} file uploaded successfully.`);
    } else if (status === 'error') {
      message.error(`${info.file.name} file upload failed.`);
    }
  };

  const handleRequest = ({ file, onError, onProgress, onSuccess }) => {
    config.update(credentials);
    const s3 = new S3();
    const _fileId = uuidv4();
    if (isEmpty(fileInfo)) {
      return;
    }
    const fileName = file.name;
    const ext = fileName.substr(fileName.lastIndexOf('.') + 1);
    const objParams = {
      Bucket: credentials.bucket,
      Key: `projects/${projectId}/${_fileId}.${ext}`,
      Body: file,
      // TODO: You should set content-type because AWS SDK will not automatically set file MIME
      // TODO: Without content-type Appium won't know how should it fileExtract file
      // Log: An unknown server-side error occurred while processing the command.
      // Original error: Could not extract Info.plist from application:
      // Plist file doesn't exist: '/var/folders/rg/wx0y__z90png8vbv8nrlyl900000gn
      // /T/2019910-53684-1d13xtp.z1b8/api-key.app/Info.plist'
      ContentType: file.type || 'application/zip',
      Expires: 86400, // TODO: Solution to solve ERR_CONNECTION_RESET when PUTting to S3 https://stackoverflow.com/questions/22798774/err-connection-reset-when-putting-to-s3
    };
    s3.putObject(objParams)
      .on('httpUploadProgress', ({ loaded, total }) => {
        onProgress(
          {
            percent: Math.round((loaded / total) * 100),
          },
          file
        );
      })
      .send((err, xData) => {
        if (err) {
          message.error(err.message);
          onError();
        } else {
          onSuccess(xData.response, file);
          // TODO: Create file record on database
          const { type: _type } = fileInfo;

          let appPlatform;
          if (_type === 'ipa' || _type === 'zip') {
            appPlatform = 'ios';
          } else if (_type === 'apk') {
            appPlatform = 'android';
          }

          onCreateFile({
            id: projectId,
            fileId: _fileId,
            ...fileInfo,
            description: '',
            appPlatform,
          });
        }
      });
  };

  const draggerProps = {
    name: 'file',
    accept: '.ipa,.zip,.apk',
    multiple: true,
    beforeUpload: handleBeforeUpload,
    onChange: handleChange,
    customRequest: handleRequest,
  };

  return (
    <>
      <Dragger {...draggerProps}>
        <p className="ant-upload-drag-icon">
          <AndroidFilled />
          <AppleFilled />
          <FileZipOutlined />
        </p>
        <p className="ant-upload-text">{t('dragAndDrop')}</p>
      </Dragger>
    </>
  );
};

AppUploader.propTypes = {
  projectId: PropTypes.string,
  onCreateFile: PropTypes.func.isRequired,
};

export default AppUploader;
