import React, { useEffect } from 'react';
import { PropTypes } from 'prop-types';
import { Form, Input } from 'antd';
import { useTranslation } from 'react-i18next';
import { noWhiteSpaceAtStartAndEnd } from 'utils/validation';
import CustomInput from 'components/Styled/CustomInput';

export default function SimpleForm({ form, name, description }) {
  const { setFieldsValue } = form;

  useEffect(() => {
    setFieldsValue({ name, description });
  }, [description, name, setFieldsValue]);

  const { t } = useTranslation();
  return (
    <Form form={form} layout="vertical">
      <Form.Item
        name="name"
        initialValue={name}
        rules={[
          {
            required: true,
            message: t('requiredProjectName'),
            whitespace: true,
          },
          {
            max: 50,
            message: t('validate.max', {
              key: t('name'),
              value: 50,
            }),
          },
          {
            validator: (_, value) => noWhiteSpaceAtStartAndEnd(value, t),
          },
        ]}
      >
        <Input addonBefore={t('name')} />
      </Form.Item>
      <Form.Item>
        <CustomInput label={t('description')}>
          <Form.Item
            noStyle
            name="description"
            initialValue={description}
            rules={[
              {
                max: 200,
                message: t('validate.max', {
                  key: t('description'),
                  value: 200,
                }),
              },
              {
                validator: (_, value) => noWhiteSpaceAtStartAndEnd(value, t),
              },
            ]}
          >
            <Input.TextArea className="remove-border-left" />
          </Form.Item>
        </CustomInput>
      </Form.Item>
    </Form>
  );
}

SimpleForm.propTypes = {
  form: PropTypes.object.isRequired,
  name: PropTypes.string,
  description: PropTypes.string,
};
