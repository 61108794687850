import {
  currentIdSelectorFactory,
  listSelectorFactory,
  loadingSelectorFactory,
  currentDataSelectorFactory,
} from 'redux/selectors';

const KEY = 'jobs';

export const currentJobIdSelector = currentIdSelectorFactory(KEY);
export const jobsSelector = listSelectorFactory(KEY);
export const jobLoadingSelector = loadingSelectorFactory(KEY);
export const currentJobSelector = currentDataSelectorFactory(KEY);
