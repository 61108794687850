import React from 'react';
import {
  DeleteOutlined,
  EditOutlined,
  LeftOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import { Tooltip, Button, Popconfirm, Typography, Divider } from 'antd';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const { Title } = Typography;

export default function ActionBar(props) {
  const { t } = useTranslation();
  const {
    title,
    onDelete,
    onEdit,
    onCreate,
    onBack,
    renderAddonBefore,
    renderAddonAfter,
    renderExpandActions,
    deleteTitle,
    editTitle,
    createTitle,
  } = props;

  return (
    <div
      style={{
        marginBottom: 20,
        justifyContent: 'space-between',
        display: 'flex',
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
        }}
      >
        {onBack && (
          <>
            <Button
              icon={<LeftOutlined />}
              onClick={onBack}
              shape="circle"
              size="large"
              style={{ marginRight: 10 }}
            />
            <Divider type="vertical" />
          </>
        )}
        {title && (
          <>
            <Title style={{ marginBottom: 0 }}>{title}</Title>
            <Divider type="vertical" />
          </>
        )}
        {renderExpandActions && renderExpandActions()}
      </div>
      <div>
        {!!renderAddonBefore ||
          !!onDelete ||
          !!onCreate ||
          (!!renderAddonAfter && <Divider type="vertical" />)}
        {!!renderAddonBefore && renderAddonBefore()}
        {!!onDelete && (
          <Tooltip title={deleteTitle}>
            <Popconfirm
              title={t('confirmDelete')}
              cancelText={t('cancel')}
              onConfirm={onDelete}
            >
              <Button
                type="danger"
                size="large"
                icon={<DeleteOutlined />}
                shape="circle"
                style={{ marginLeft: 10 }}
              />
            </Popconfirm>
          </Tooltip>
        )}
        {!!onEdit && (
          <Tooltip title={editTitle}>
            <Button
              type="dashed"
              size="large"
              icon={<EditOutlined />}
              shape="circle"
              style={{ marginLeft: 10 }}
              onClick={onEdit}
            />
          </Tooltip>
        )}
        {!!onCreate && (
          <Tooltip title={createTitle}>
            <Button
              size="large"
              type="primary"
              icon={<PlusOutlined />}
              shape="circle"
              style={{ marginLeft: 10 }}
              onClick={onCreate}
            />
          </Tooltip>
        )}
        {renderAddonAfter && renderAddonAfter()}
      </div>
    </div>
  );
}

ActionBar.propTypes = {
  title: PropTypes.string,
  onDelete: PropTypes.func,
  onEdit: PropTypes.func,
  onCreate: PropTypes.func,
  onBack: PropTypes.func,
  renderAddonBefore: PropTypes.func,
  renderAddonAfter: PropTypes.func,
  renderExpandActions: PropTypes.func,
  deleteTitle: PropTypes.string,
  editTitle: PropTypes.string,
  createTitle: PropTypes.string,
};
