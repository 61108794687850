import React from 'react';
import PropTypes from 'prop-types';
import Label from 'components/Styled/Label';
import { Typography } from 'antd';
import upperCase from 'lodash/upperCase';
import { useTranslation } from 'react-i18next';

const { Text } = Typography;

export default function TestSuiteConfigDisplay({
  deviceType,
  target,
  platformName,
  bundleId,
  fileId,
  file,
  appPackage,
  appActivity,
  teamId,
  teamName,
}) {
  const { t } = useTranslation();

  return (
    <div>
      <Label>{upperCase(t('configuration'))}: </Label>
      <ul style={{ paddingLeft: 10, listStyle: 'none' }}>
        {deviceType && (
          <li>
            <Text strong>{t('deviceType')}: </Text>
            <Text>{t(`${deviceType}`)}</Text>
          </li>
        )}
        {teamId && teamName && (
          <li>
            <Text strong>{t('teamName')}: </Text>
            <Text>{`${teamName} (${teamId})`}</Text>
          </li>
        )}
        {target === 'browser' && (
          <li>
            <Text strong>{t('browser')}: </Text>
            <Text>{platformName === 'ios' ? 'Safari' : 'Default browser'}</Text>
          </li>
        )}
        {bundleId && (
          <li>
            <Text strong>{t('bundleId')}: </Text>
            <Text>{bundleId}</Text>
          </li>
        )}
        {appPackage && (
          <li>
            <Text strong>{t('appPackage')}: </Text>
            <Text>{appPackage}</Text>
          </li>
        )}
        {appActivity && (
          <li>
            <Text strong>{t('appActivity')}: </Text>
            <Text>{appActivity}</Text>
          </li>
        )}
        {fileId && (
          <li>
            <Text strong>{t('fileDetail')}: </Text>
            <ul style={{ paddingLeft: 18, listStyleType: 'circle' }}>
              <li>
                <Text strong>{t('name')}: </Text>
                <Text>{file.name}</Text>
              </li>
              <li>
                <Text strong>{t('appName')}: </Text>
                <Text>{file.appName}</Text>
              </li>
              <li>
                <Text strong>{t('minimumOSVer')}: </Text>
                <Text>{file.minOSVersion}</Text>
              </li>
              {file.appPlatform === 'ios' && (
                <li>
                  <Text strong>{t('bundleId')}: </Text>
                  <Text>{file.bundleId}</Text>
                </li>
              )}
              {file.appPlatform === 'android' && (
                <li>
                  <Text strong>{t('appPackage')}: </Text>
                  <Text>{file.appPackage}</Text>
                </li>
              )}
              {file.appPlatform === 'android' && (
                <li>
                  <Text strong>{t('appActivity')}: </Text>
                  <Text>{file.appActivity}</Text>
                </li>
              )}
            </ul>
          </li>
        )}
      </ul>
    </div>
  );
}

TestSuiteConfigDisplay.propTypes = {
  deviceType: PropTypes.string,
  target: PropTypes.string,
  platformName: PropTypes.string,
  bundleId: PropTypes.string,
  appPackage: PropTypes.string,
  appActivity: PropTypes.string,
  fileId: PropTypes.string,
  file: PropTypes.object,
  teamId: PropTypes.string,
  teamName: PropTypes.string,
};
