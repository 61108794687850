import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { List, Input, Select } from 'antd';
import { useTranslation } from 'react-i18next';
import FileCard from './FileCard';

const { Option } = Select;
const { Search } = Input;

export default function AppFileList({
  data,
  loading,
  column,
  selectedFileId,
  hideSelectType,
  onDelete,
  onSelect,
  onSearch,
  pageSize,
}) {
  const [selectedId, setSelectedId] = useState(selectedFileId);
  const { t } = useTranslation();

  const handleSelectFile = (id) => {
    if (onSelect) {
      setSelectedId(id);
      onSelect(id);
    }
  };

  const [type, setType] = useState('all');

  const handleSearch = (value) => {
    onSearch(value, type);
  };

  return (
    <div>
      <Search
        addonBefore={
          !hideSelectType && (
            <Select defaultValue="all" onChange={setType}>
              <Option value="all">{t('allFiles')}</Option>
              <Option value="ipa">{t('deviceIPA')}</Option>
              <Option value="zip">{t('simAPP')}</Option>
              <Option value="apk">{t('deviceAPK')}</Option>
            </Select>
          )
        }
        style={{ marginTop: 20 }}
        placeholder={t('searchPlaceholder')}
        enterButton={t('search')}
        size="large"
        onSearch={handleSearch}
      />
      <List
        pagination={{ pageSize }}
        grid={{ gutter: 16, column }}
        style={{ marginTop: 20 }}
        dataSource={data}
        loading={loading}
        renderItem={(item) => (
          <List.Item onClick={() => handleSelectFile(item.id)}>
            <FileCard data={item} selectedId={selectedId} onDelete={onDelete} />
          </List.Item>
        )}
      />
    </div>
  );
}

AppFileList.propTypes = {
  data: PropTypes.array,
  loading: PropTypes.bool,
  column: PropTypes.number,
  pageSize: PropTypes.number,
  selectedFileId: PropTypes.string,
  onDelete: PropTypes.func,
  onSelect: PropTypes.func,
  onSearch: PropTypes.func,
  hideSelectType: PropTypes.bool,
};

AppFileList.defaultProps = {
  column: 4,
  data: [],
  pageSize: 10,
};
