import React, { useState, useEffect, useCallback } from 'react';
import Icon from '@ant-design/icons';
import SvgIcons from 'images/svg';
import { Popover, Typography, Button, Tooltip } from 'antd';
import { HUMANCREST_CONTACT_US } from 'data/urls';
import { useI18next } from 'gatsby-plugin-react-i18next';
import PropTypes from 'prop-types';
import debounce from 'lodash/debounce';

const { Text } = Typography;

export default function ContactUs({ isMobile, fixed, size, style }) {
  const { t } = useI18next();
  const [isShow, setIsShow] = useState(false);

  const showChat = useCallback(() => {
    if (window.scrollY > 30) {
      setIsShow(true);
    } else {
      setIsShow(false);
    }
  }, []);

  const debounceShowChat = debounce(showChat, 100);

  useEffect(() => {
    window.addEventListener('scroll', debounceShowChat);

    return () => {
      window.removeEventListener('scroll', debounceShowChat);
    };
  }, [debounceShowChat]);

  const onClickSlack = () => {
    window.open(process.env.GATSBY_SLACK_INVITE_URL, '_blank');
  };

  const onClickEmail = () => {
    window.open(HUMANCREST_CONTACT_US, '_blank');
  };

  const title = <Text strong>{t('contactUs')}</Text>;
  const content = (
    <div>
      <Button
        icon={<Icon component={SvgIcons.Slack} />}
        onClick={onClickSlack}
        style={{ width: 150 }}
        size="large"
      >
        Slack
      </Button>
      <br />
      <br />
      <Button
        icon={<Icon component={SvgIcons.Email} />}
        style={{ width: 150 }}
        onClick={onClickEmail}
        size="large"
      >
        E-mail
      </Button>
      <br />
      <br />
    </div>
  );

  if (isMobile && !isShow) {
    return null;
  }

  // prettier-ignore
  const fixedStyled = fixed
    ? {
      position: 'fixed',
      bottom: 20,
      right: 20,
      cursor: 'pointer',
    }
    : {};

  return (
    <Tooltip title={t('contactUs')}>
      <Popover
        placement="topRight"
        title={title}
        content={content}
        trigger="click"
      >
        <Icon
          component={SvgIcons.ChatColor}
          style={{
            fontSize: size,
            ...fixedStyled,
            ...style,
          }}
        />
      </Popover>
    </Tooltip>
  );
}

ContactUs.defaultProps = {
  size: 60,
  style: {},
};

ContactUs.propTypes = {
  isMobile: PropTypes.bool,
  fixed: PropTypes.bool,
  size: PropTypes.number,
  style: PropTypes.object,
};
