/* eslint-disable react/jsx-indent */
import React from 'react';
import { PropTypes } from 'prop-types';
import { isSignedIn, isBrowser } from 'utils/auth';
import { useI18next } from 'gatsby-plugin-react-i18next';

function PrivateRoute({ component: Component, location, ...rest }) {
  const { navigate } = useI18next();

  if (!isBrowser) {
    return null;
  }
  if (!isSignedIn() && location?.pathname !== '/auth/sign-in') {
    // If we’re not logged in, redirect to the home page.
    navigate('/auth/sign-in/', { state: { from: location } });
    return null;
  }

  return <Component {...rest} />;
}

PrivateRoute.propTypes = {
  component: PropTypes.elementType,
  location: PropTypes.object,
};

export default PrivateRoute;
