import React, { useEffect, useState } from 'react';
import { SyncOutlined, DisconnectOutlined } from '@ant-design/icons';
import { Button, Popover, Badge } from 'antd';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import PropTypes from 'prop-types';
import ClientDevices from 'components/ClientDevices';
import { useSelector } from 'react-redux';
import {
  clientDevicesFilterSelector,
  localClientIdSelector,
  connectedClientIdsFilterSelector,
} from 'redux/ClientDevices/selectors';

export default function ClientStatus({ style }) {
  const { t } = useTranslation();
  const [selectedClientId, setSelectedClientId] = useState();

  const localClientId = useSelector(localClientIdSelector);
  const clientIdsFilter = useSelector(connectedClientIdsFilterSelector);
  const clientIds = clientIdsFilter();
  const devicesByClientFilter = useSelector(clientDevicesFilterSelector);
  const clientDevices = devicesByClientFilter(selectedClientId);

  useEffect(() => {
    if (!selectedClientId && clientIds.length) {
      setSelectedClientId(clientIds[0]);
    }
  }, [clientIds, selectedClientId]);

  const handleChangeTab = (key) => {
    setSelectedClientId(key);
  };

  const content = (
    <ClientDevices
      size="small"
      isMini
      clientDevices={clientDevices}
      clientIds={clientIds}
      localClientId={localClientId}
      onChangeTab={handleChangeTab}
    />
  );

  return (
    <Popover
      content={content}
      title={
        clientIds.length ? t('connectedToClient') : t('disconnectedFromClient')
      }
      trigger="hover"
      style={style}
    >
      <Badge count={clientIds.length} showZero>
        <Button
          type="primary"
          danger={!clientIds.length}
          shape="round"
          style={{ paddingRight: 20 }}
        >
          {clientIds.length ? <SyncOutlined spin /> : <DisconnectOutlined />}
          {t('moppiumClient')}
        </Button>
      </Badge>
    </Popover>
  );
}

ClientStatus.propTypes = {
  style: PropTypes.object,
};
