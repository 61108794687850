import React from 'react';
import PropTypes from 'prop-types';
import './index.less';

export default function JustifyContentCenterWrapper({ children, style }) {
  return (
    <div className="just-content-center-wrapper" style={style}>
      {children}
    </div>
  );
}

JustifyContentCenterWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  style: PropTypes.object,
};
