import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Cascader, Form, Select, Button, Input } from 'antd';
import { useTranslation } from 'react-i18next';
import { parseCascaderOptions } from 'utils/parse-cascader-options';
import { DEVICE, IOS } from 'redux/ClientDevices/slice';
import CustomInput from 'components/Styled/CustomInput';
import AppleTeamCreationDrawer from 'containers/Settings/AppleTeamCreationDrawer';
import PRESETS from './PresetLabel';

export default function TestSuitePlatformSelectionSection({
  onSetFieldsValue,
  onChangePlaftorm,
  appleTeams,
  teamId,
}) {
  const { t } = useTranslation();
  const [platformName, setPlatformName] = useState();
  const [deviceType, setDeviceType] = useState();
  const [visibleDrawer, setVisibleDrawer] = useState();

  useEffect(() => {
    onSetFieldsValue({ teamId });
  }, [onSetFieldsValue, teamId]);

  const handleCancelDrawer = useCallback(() => {
    setVisibleDrawer(false);
  }, []);

  const handleAddAppleTeam = useCallback(() => {
    setVisibleDrawer(true);
  }, []);

  const translatedPresets = parseCascaderOptions(PRESETS, (label) => t(label));

  const handleChangePlatform = (value, selectedOptions) => {
    const [_platformName, _deviceType, target] = value;
    setPlatformName(_platformName);
    setDeviceType(_deviceType);
    onSetFieldsValue({
      platformName: _platformName,
      deviceType: _deviceType,
      target,
    });
    onChangePlaftorm(value, selectedOptions);
  };

  return (
    <>
      <Form.Item>
        <Cascader
          showSearch
          style={{ width: '100%' }}
          options={translatedPresets}
          onChange={handleChangePlatform}
          placeholder={t('selectPlatform')}
        />
        <Form.Item name="platformName" noStyle>
          <Input style={{ display: 'none' }} />
        </Form.Item>
        <Form.Item name="deviceType" noStyle>
          <Input style={{ display: 'none' }} />
        </Form.Item>
        <Form.Item
          name="target"
          rules={[
            {
              required: true,
              message: t('selectPlatform'),
            },
          ]}
          noStyle
        >
          <Input style={{ display: 'none' }} />
        </Form.Item>
      </Form.Item>
      {platformName === IOS && deviceType === DEVICE && (
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <Form.Item style={{ flexGrow: 1 }}>
            <CustomInput label={t('teamName')}>
              <Form.Item
                name="teamId"
                rules={[
                  {
                    required: true,
                    message: t('requiredSelectTeamId'),
                  },
                ]}
                noStyle
              >
                <Select
                  placeholder={t('selectAppleTeam')}
                  className="remove-border-left"
                  allowClear
                >
                  {appleTeams?.map((team) => (
                    <Select.Option key={team.id} value={team.teamId}>
                      {`${team.teamName} (${team.teamId})`}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </CustomInput>
          </Form.Item>
          <Button style={{ marginLeft: 20 }} onClick={handleAddAppleTeam}>
            {t('addAppleTeam')}
          </Button>
        </div>
      )}
      <AppleTeamCreationDrawer
        visible={visibleDrawer}
        onCancel={handleCancelDrawer}
      />
    </>
  );
}

TestSuitePlatformSelectionSection.propTypes = {
  onSetFieldsValue: PropTypes.func,
  onChangePlaftorm: PropTypes.func.isRequired,
  appleTeams: PropTypes.array,
  teamId: PropTypes.string,
};
