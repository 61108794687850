import React from 'react';
import { Empty, Button, List } from 'antd';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import isEqual from 'react-fast-compare';
import TestSuiteItem from './TestSuiteItem';

function TestSuiteList({ onShowTestSuiteCreationForm, loading, data }) {
  const { t } = useTranslation();

  const empty = (
    <Empty description={<span>{t('noTestSuite')}</span>}>
      <Button type="primary" onClick={onShowTestSuiteCreationForm}>
        {t('createNow')}
      </Button>
    </Empty>
  );

  return (
    <List
      grid={{ gutter: 16, column: 3 }}
      pagination={{ pageSize: 9 }}
      locale={{ emptyText: empty }}
      loading={loading}
      itemLayout="horizontal"
      dataSource={data}
      renderItem={(item) => (
        <List.Item>
          <TestSuiteItem data={item} />
        </List.Item>
      )}
    />
  );
}

TestSuiteList.propTypes = {
  onShowTestSuiteCreationForm: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  data: PropTypes.array,
};

TestSuiteList.defaultProps = {
  data: [],
};

export default React.memo(TestSuiteList, isEqual);
