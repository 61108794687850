import React, { useRef, useState, useCallback, useEffect } from 'react';
import { Input, Typography, Tag, Tooltip, Form } from 'antd';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

const { Text } = Typography;

function NotificationSection({ emails: _emails, form }) {
  const { setFieldsValue, validateFields } = form;
  const { t } = useTranslation();
  const [emails, setEmails] = useState(_emails || []);
  const emailInputRef = useRef();

  useEffect(() => {
    if (_emails) {
      setFieldsValue({ emails: _emails });
      setEmails(_emails);
    }
  }, [_emails, setFieldsValue]);

  const handleInputEmailConfirm = useCallback(async () => {
    try {
      const { email } = await validateFields(['email']);
      if (email && emails.indexOf(email) === -1) {
        const payload = [...emails, email];
        setFieldsValue({ emails: payload, email: null });
        setEmails(payload);
        emailInputRef.current.focus();
      }

      // setInputEmailVisible(false);
    } catch (error) {
      // Passed
    }
  }, [emails, setFieldsValue, validateFields]);

  const handleRemoveMail = (removedMail) => {
    const remainEmails = emails.filter((mail) => mail !== removedMail);
    setFieldsValue({ emails: remainEmails });
    setEmails(remainEmails);
    setTimeout(() => {
      emailInputRef.current.focus();
    }, 500);
  };

  return (
    <>
      <Form.Item>
        {emails.length < 5 && (
          <Form.Item
            name="email"
            rules={[
              {
                type: 'email',
                message: t('invalidEmail'),
              },
            ]}
            noStyle
          >
            <Input
              ref={emailInputRef}
              type="text"
              style={{ width: 200 }}
              onBlur={handleInputEmailConfirm}
              onPressEnter={handleInputEmailConfirm}
              placeholder="johndoe@example.com"
            />
          </Form.Item>
        )}
        {!(emails.length < 5) && (
          <Text type="warning">{t('maxEmailAddress')}</Text>
        )}
      </Form.Item>
      <Form.Item>
        {emails &&
          emails.map((mail) => {
            const isLongEmail = mail?.length > 20;
            const tagElem = (
              <Tag key={mail} closable onClose={() => handleRemoveMail(mail)}>
                {isLongEmail ? `${mail.slice(0, 20)}...` : mail}
              </Tag>
            );
            return isLongEmail ? (
              <Tooltip title={mail} key={mail}>
                {tagElem}
              </Tooltip>
            ) : (
              tagElem
            );
          })}
      </Form.Item>
      <Form.Item name="emails" initialValue={emails} noStyle>
        <Input style={{ display: 'none' }} />
      </Form.Item>
    </>
  );
}

NotificationSection.propTypes = {
  form: PropTypes.object.isRequired,
  emails: PropTypes.array,
};

export default NotificationSection;
